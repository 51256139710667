<template>
  <div>
    <LaboratoryAdd
      v-if="isAddNewLaboratorySidebarActive"
      :is-add-new-laboratory-sidebar-active.sync="
        isAddNewLaboratorySidebarActive
      "
      @createLaboratory="createLaboratory"
    />
    <b-row class="mt-2">
      <b-col class="mb-2">
        <h5 class="mb-0">Laboratory</h5>
        <small class="text-muted">Enter the patient's labs.</small>
      </b-col>
      <b-col md="12" lg="7">
        <CardStats
          :totalPriceSection="totalPriceLaboratory"
          :totalPrice="totalPrice"
          :totalPriceWithInsurance="totalFinalWithInsurance"
          :insuranceDetails="insuranceDetails"
        />
      </b-col>
    </b-row>
    <b-row class="mt-0 w-100">
      <b-col v-if="promotionOfficeVisit.laboratoryPromotion.length > 0" class="mb-1">
        <label for="">Promotions</label>
        <v-select
          :components="{ Deselect }"
          id="mySelect"
          :noDrop="true"
          :searchable="false"
          v-model="promotionOfficeVisit.laboratoryPromotion"
          multiple
          label="name"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="4"
        lg="6"
        xl="4"
        class="my-1"
        v-for="item in laboratoryLength.data"
        :key="item.id"
      >
        <b-form-group :label-for="item.name">
          <div class="d-flex justify-content-between">
            <label for="">{{ item.name }}</label>
            <div
              ref=""
              v-for="(item, i) in validResultsList(
                laboratorySelected[item.name]
              )"
              :key="i"
            >
              <b-button
                v-if="item.status"
                variant="flat-warning"
                class="btn-icon rounded-circle p-0"
                v-b-tooltip.hover.top="
                  `El procedimiento ${item.names} no fue realizado, por favor actualizar orden`
                "
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </div>
            <b-form-checkbox
              v-if="item.price != null"
              name="checkbox-input"
              v-model="checkCategoryVariable[item.id]"
              @change="checkCategory($event, item)"
            />
          </div>
          <v-select
            :disabled="checkCategoryVariable[item.id]"
            id="mySelect"
            v-model="laboratorySelected[item.name]"
            @search="filterLaboratory($event, item.id)"
            @option:selected="addItem($event)"
            @option:deselected="addItem($event)"
            :options="item.laboratoriesM"
            multiple
            label="name"
          />
        </b-form-group>
      </b-col>
      <b-col md="3" class="d-flex flex-column">
        <label for="">{{ $t("Add") }} Laboratory</label>
        <b-button
          variant="success"
          class="btn-icon"
          @click="isAddNewLaboratorySidebarActive = true"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormCheckbox,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";

import axiosLC from "@/services/admin/consults/laboratoryCategories";
import laboratories from "@/services/admin/consults/laboratories";

import LaboratoryAdd from "@/views/admin/type-consult/laboratory/LaboratoryAdd.vue";
import CardStats from "../notification/CardStats";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    vSelect,

    LaboratoryAdd,
    CardStats,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    laboratorySelected: {
      type: Object,
      required: true,
    },
    laboratoryLength: {
      type: Object,
      required: true,
    },
    procedurePacks: {
      type: Object,
      required: true,
    },
    totalPriceLaboratory: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
    insuranceDetails: {
      type: Object,
      required: true,
    },
    totalFinalWithInsurance: {
      type: Number,
      default: 0,
    },
    visitType: {
      type: Number,
      required: true,
    },
    isInsuranceCondition: {
      type: Boolean,
      required: true,
    },
    promotionOfficeVisit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkCategoryVariable: this.procedurePacks.checkCategoryLaboratory,
      isAddNewLaboratorySidebarActive: false,
      setTimeoutBuscador: "",
      Deselect: {
        render: (createElement) => createElement("span"),
      },
      isCheckPromotion: false,
    };
  },
  methods: {
    validResultsList(item) {
      if (item != undefined) {
        const names = [];
        item.forEach((element) => {
          if (element.pivot.status === false) {
            names.push(element.name);
          }
        });
        if (names.length > 0) {
          return [
            {
              status: true,
              names: names.join(", "),
            },
          ];
        } else {
          return item;
        }
      }
      return item;
    },
    getReviewSystemCategory() {
      axiosLC.categoryLabList(20).then(({ data }) => {
        const catLaboratories = data.map(({ laboratories, ...res }) => {
          const laboratoriesM = laboratories.filter(
            (laboratory) => laboratory.price != null
          );
          return {
            laboratoriesM,
            ...res,
          };
        });
        this.laboratoryLength.data = catLaboratories;
      });
    },
    checkCategory(event, item) {
      if (event == true) {
        this.addPack(item);
        const findLab = this.procedurePacks.laboratoriesPack.filter(
          (laboratory) => {
            return laboratory.id === item.id;
          }
        );
        if (findLab.length == 0) {
          this.procedurePacks.laboratoriesPack.push(item);
          if (Array.isArray(this.laboratorySelected[item.name])) {
            this.laboratorySelected[item.name] = [];
          }
        }
      } else {
        this.addPack(item, "delete");
        const newArray = this.procedurePacks.laboratoriesPack.filter(
          (laboratory) => laboratory.id !== item.id
        );
        this.procedurePacks.laboratoriesPack = newArray;
      }
    },
    createLaboratory(value) {
      this.isAddNewLaboratorySidebarActive = false;
      this.getReviewSystemCategory();
    },
    filterLaboratory(name, id) {
      clearTimeout(this.setTimeoutBuscador);
      this.setTimeoutBuscador = setTimeout(() => {
        const data = {
          name,
          visit_type_id: this.visitType,
          insurance: this.isInsuranceCondition,
        };
        laboratories
          .laboratoryFilter(10, data)
          .then(({ registro: { data } }) => {
            //encontrar en el array el id y reemplazar el array laboratoriesM solo los que categories[0].id === id
            const findIndex = this.laboratoryLength.data.findIndex(
              (item) => item.id === id
            );
            const laboratoriesM = data.filter(
              (laboratory) =>
                laboratory.categories[0] && laboratory.categories[0].id === id
            );
            this.laboratoryLength.data[findIndex].laboratoriesM = laboratoriesM;
          });
      }, 300);
    },
    addItem(item) {
      if (Array.isArray(item)) {
        /* add element */
        const data = {
          id: item[item.length - 1].id,
          type: 2,
          itemId: 6,
          total: item[item.length - 1].price,
        };
        this.$emit("calculateInsurance", data);
      } else {
        /* delete element */
        const data = { ...item, type: 2, itemId: 6 };
        this.$emit("calculateInsurance", data, "delete");
      }
    },
    addPack(item, method = "create") {
      const data = { id: item.id, type: 2, itemId: 62, total: item.price };
      this.$emit("calculateInsurance", data, method);
    },
  },
};
</script>
<style scoped>
#mySelect >>> .vs__dropdown-menu {
  min-width: 350px;
}
.card-total .card-body {
  padding: 0 !important;
}
</style>
