import { userAxios } from '@/services'

const reviewSystemCategoryList = async (perPage) => {
    try {
        return await userAxios.get(`category_review_system/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const reviewSystemCategoryCreate = async (data) => {
    try {
        return await userAxios.post('category_review_system', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const reviewSystemCategoryUpdate = async (id, data) => {
    try {
        return await userAxios.put(`category_review_system/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
} 

const reviewSystemCategoryDelete = async (id) => {
    try {
        return await userAxios.delete(`category_review_system/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const reviewSystemCategoryPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`category_review_system/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const reviewSystemCategoryFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`category_review_system/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const reviewSystemCategoryFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`category_review_system/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    reviewSystemCategoryList,
    reviewSystemCategoryCreate,
    reviewSystemCategoryUpdate,
    reviewSystemCategoryDelete,
    reviewSystemCategoryPagination,
    reviewSystemCategoryFilter,
    reviewSystemCategoryFilterPagination
}