<template>
  <div>
    <TestAdd
      :is-add-new-test-sidebar-active.sync="isAddNewTestSidebarActive"
      @createTest="createTest"
    />
    <ProceduresAdd
      :is-add-new-procedures-sidebar-active.sync="
        isAddNewProceduresSidebarActive
      "
      @createProcedures="createProcedures"
    />
    <b-row class="mt-2">
      <b-col md="12" lg="5" class="mb-2">
        <h5 class="mb-0">IH interventions</h5>
        <small class="text-muted">Enter the interventions.</small>
      </b-col>
      <b-col md="12" lg="7">
        <CardStats
          :totalPriceSection="totalPriceInterventions"
          :totalPrice="totalPrice"
          :totalPriceWithInsurance="totalFinalWithInsurance"
          :insuranceDetails="insuranceDetails"
        />
      </b-col>
      <b-col md="4" lg="5">
        <validation-provider #default="{ errors }" name="Test">
          <b-form-group
            label-for="test"
            :state="errors.length > 0 ? false : null"
          >
            <div class="d-flex justify-content-between">
              <label for="">Test</label>
              <div
                ref=""
                v-for="(item, i) in validResultsList(
                  interventionsProcedure.tests
                )"
                :key="i"
              >
                <b-button
                  v-if="item.status && item.names"
                  variant="flat-warning"
                  class="btn-icon rounded-circle p-0"
                  v-b-tooltip.hover.top="
                    `El procedimiento ${item.names} no fue realizado, por favor actualizar orden`
                  "
                >
                  <feather-icon icon="DeleteIcon" />
                </b-button>
              </div>
            </div>
            <v-select
              id="mySelect"
              v-model="interventionsProcedure.tests"
              @search="searchTests"
              :options="testOptions.data"
              multiple
              @option:selected="addTest($event)"
              @option:deselected="addTest($event)"
              label="name"
            >
            </v-select>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="promotionOfficeVisit.promotionTest.length > 0"
            label="Promotion"
          >
            <v-select
              :components="{ Deselect }"
              id="mySelect"
              v-model="promotionOfficeVisit.promotionTest"
              multiple
              :noDrop="true"
              :searchable="false"
              label="name"
            ></v-select>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="2" lg="1" class="d-flex flex-column">
        <label for="">{{ $t("Add") }}</label>
        <b-button
          variant="success"
          class="btn-icon mb-2"
          @click="isAddNewTestSidebarActive = true"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-col>
      <b-col md="4" lg="5">
        <validation-provider #default="{ errors }" name="Procedure">
          <b-form-group
            label-for="procedure"
            :state="errors.length > 0 ? false : null"
          >
            <div class="d-flex justify-content-between">
              <label for="">Procedure</label>
              <div
                ref=""
                v-for="(item, i) in validResultsList(
                  interventionsProcedure.procedures
                )"
                :key="i"
              >
                <b-button
                  v-if="item.status && item.names"
                  variant="flat-warning"
                  class="btn-icon rounded-circle p-0"
                  v-b-tooltip.hover.top="
                    `El procedimiento ${item.names} no fue realizado, por favor actualizar orden`
                  "
                >
                  <feather-icon icon="DeleteIcon" />
                </b-button>
              </div>
            </div>
            <v-select
              id="mySelect"
              v-model="interventionsProcedure.procedures"
              @search="searchProcedures"
              :options="procedureOptions.data"
              @option:selected="addProcedure($event)"
              @option:deselected="addProcedure($event)"
              multiple
              label="name"
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            v-if="promotionOfficeVisit.promotionProcedure.length > 0"
            label="Promotion"
          >
              <v-select
                :components="{ Deselect }"
                id="mySelect"
                v-model="promotionOfficeVisit.promotionProcedure"
                multiple
                :noDrop="true"
                :searchable="false"
                label="name"
              />
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="2" lg="1" class="d-flex flex-column">
        <label for="">{{ $t("Add") }} P/D</label>
        <b-button
          variant="success"
          class="btn-icon"
          @click="isAddNewProceduresSidebarActive = true"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BFormGroup,
  BFormCheckbox,
  BFormInvalidFeedback,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";

import TestAdd from "@/views/admin/type-consult/test/TestAdd.vue";
import ProceduresAdd from "@/views/admin/type-consult/procedures/ProceduresAdd.vue";
import CardStats from "../notification/CardStats";

import axiosT from "@/services/admin/consults/tests";
import axiosP from "@/services/admin/consults/procedures";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BBadge,
    BFormInvalidFeedback,
    vSelect,

    TestAdd,
    ProceduresAdd,
    CardStats,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    interventionsProcedure: {
      type: Object,
      required: true,
    },
    totalPriceInterventions: {
      type: Number,
      default: 0,
    },
    testOptions: {
      type: Object,
      required: true,
    },
    procedureOptions: {
      type: Object,
      required: true,
    },
    insuranceDetails: {
      type: Object,
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
    totalFinalWithInsurance: {
      type: Number,
      default: 0,
    },
    visitType: {
      type: Number,
      required: true,
    },
    isInsuranceCondition: {
      type: Boolean,
      required: true,
    },
    promotionOfficeVisit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      setTimeoutBuscador: "",
      isAddNewTestSidebarActive: false,
      isAddNewProceduresSidebarActive: false,
      checkPromotion: {
        procedures: false,
        tests: false,
      },
      Deselect: {
        render: (createElement) => createElement("span"),
      },
      isCheckPromotion: false,
    };
  },
  mounted() {
    this.getData();
    this.asignPromotion();
  },
  watch: {
    interventionsProcedure() {
      this.interventionsProcedure.tests;
    },
  },
  methods: {
    getTests() {
      axiosT.testList(10).then(({ data }) => {
        const arrayUserOptions = this.promotionOfficeVisit.promotionTest;
        this.testOptions.data = data.filter(
          ({ id: id1 }) => !arrayUserOptions.some(({ id: id2 }) => id2 === id1)
        );
      });
    },
    getProcedures() {
      axiosP.procedureList(10).then(({ data }) => {
        const arrayUserOptions = this.promotionOfficeVisit.promotionProcedure;
        this.procedureOptions.data = data.filter(
          ({ id: id1 }) => !arrayUserOptions.some(({ id: id2 }) => id2 === id1)
        );
      });
    },
    searchTests(ailment, loading) {
      if (ailment.length) {
        loading(true);
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: ailment,
          visit_type_id: this.visitType,
          insurance: this.isInsuranceCondition,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosT.testFilter(10, datos).then(({ registro }) => {
            loading(false);
            const arrayUserOptions = this.promotionOfficeVisit.promotionTest;
            this.testOptions.data = registro.data.filter(
              ({ id: id1 }) =>
                !arrayUserOptions.some(({ id: id2 }) => id2 === id1)
            );
          });
        }, 350);
      }
    },
    searchProcedures(allergy, loading) {
      if (allergy.length) {
        loading(true);
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: allergy,
          visit_type_id: this.visitType,
          insurance: this.isInsuranceCondition,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosP.procedureFilter(10, datos).then(({ registro }) => {
            loading(false);
            const arrayUserOptions =
              this.promotionOfficeVisit.promotionProcedure;
            this.procedureOptions.data = registro.data.filter(
              ({ id: id1 }) =>
                !arrayUserOptions.some(({ id: id2 }) => id2 === id1)
            );
          });
        }, 350);
      }
    },
    createTest(value) {
      this.isAddNewTestSidebarActive = false;
      this.getTests();
    },
    createProcedures(value) {
      this.isAddNewProceduresSidebarActive = false;
      this.getProcedures();
    },
    getData() {
      /* validar insurance */
      this.interventionsProcedure.tests.forEach((item) => {
        this.addTest([item]);
      });
      this.interventionsProcedure.procedures.forEach((item) => {
        this.addProcedure([item]);
      });
    },
    asignPromotion() {
      this.promotionOfficeVisit.promotionProcedure = this.clearPromotion(
        this.procedureOptions,
        "procedures"
      );
      this.promotionOfficeVisit.promotionTest = this.clearPromotion(
        this.testOptions,
        "tests"
      );
    },
    clearPromotion(paramProcedureOptios, nameData) {
      const { data: procedureOptions } = paramProcedureOptios;
      const arrayOptions = procedureOptions;
      const arrayUserOptions = this.interventionsProcedure[nameData];

      const promotions = arrayUserOptions.filter(
        (item) => item.pivot.promotion
      );

      if (promotions.length > 0) {
        paramProcedureOptios.data = arrayOptions.filter(
          ({ id: id1 }) => !arrayUserOptions.some(({ id: id2 }) => id2 === id1)
        );
      }
      promotions.forEach((element) => {
        this.checkPromotion[nameData] = true;
        const index = arrayUserOptions.findIndex((x) => x.id === element.id);
        this.interventionsProcedure[nameData].splice(index, 1);
      });

      return promotions;
    },
    validResultsList(item) {
      if (item != undefined) {
        const names = [];
        item.forEach((element) => {
          if (element.pivot && element.pivot.status === false) {
            names.push(element.name);
          }
        });
        if (names.length > 0) {
          return [
            {
              status: true,
              names: names.join(", "),
            },
          ];
        } else {
          return item;
        }
      }
      return item;
    },
    addProcedure(item) {
      if (Array.isArray(item)) {
        /* add element */
        const data = {
          id: item[item.length - 1].id,
          type: 1,
          itemId: 7,
          total: item[item.length - 1].price,
        };
        this.$emit("calculateInsurance", data);
      } else {
        /* delete element */
        const data = { ...item, type: 1, itemId: 7 };
        this.$emit("calculateInsurance", data, "delete");
      }
    },
    addTest(item) {
      if (Array.isArray(item)) {
        /* add element */
        const data = {
          id: item[item.length - 1].id,
          type: 1,
          itemId: 71,
          total: item[item.length - 1].price,
        };
        this.$emit("calculateInsurance", data);
      } else {
        /* delete element */
        const data = { ...item, type: 1, itemId: 71 };
        this.$emit("calculateInsurance", data, "delete");
      }
    },
  },
};
</script>

<style scoped>
#mySelect >>> .vs__dropdown-menu {
  min-width: 320px;
}
</style>
