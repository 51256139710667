<template>
  <b-row class="mt-2">
    <CheckConsent
      v-if="conditionCheckConsent"
      :dataTreatment="dataTreatment"
      @closeModalConset="closeModalConset"
      @saveConsent="saveConsent"
    />
    <b-col md="12" lg="5" class="mb-2">
      <h5 class="mb-0">Therapeutic Injections / Immunizations</h5>
      <small class="text-muted">Enter the corresponding treatments</small>
    </b-col>
    <b-col md="12" lg="7">
      <CardStats
        :totalPriceSection="totalPriceTreatment"
        :totalPrice="totalPrice"
        :totalPriceWithInsurance="totalFinalWithInsurance"
        :insuranceDetails="insuranceDetails"
      />
      <!-- <b-card class="card-total">
        <div class="d-flex rounded-lg">
          <div class="d-flex w-100 flex-column align-items-start m-1">
            <p for="" class="mb-0">Deductible ($)</p>
            <h5 class="font-weight-bolder">{{ insuranceDetails.deductibleProgress }}</h5>
          </div>
          <div class="d-flex w-100 flex-column align-items-start m-1">
            <p for="" class="mb-0">COI-Fee (%)</p>
            <h5 class="font-weight-bolder">{{ insuranceDetails.coInsuragePercentage }}</h5>
          </div>
          <div class="d-flex w-100 flex-column align-items-start m-1">
            <p for="" class="mb-0">COI-Fee (%)</p>
            <h5 class="font-weight-bolder">{{ insuranceDetails.coInsuragePercentage }}</h5>
          </div>
          COI-Charges
          <div class="d-flex w-100 flex-column align-items-start m-1">
            <p for="" class="mb-0">Section ($)</p>
            <h5 class="font-weight-bolder">{{ totalPriceTreatment }}</h5>
          </div>
          <div class="d-flex w-100 flex-column align-items-start m-1">
            <p for="" class="mb-0">Total ($)</p>
            <h5 class="font-weight-bolder">{{ totalPrice }}</h5>
          </div>
        </div>
      </b-card> -->
    </b-col>
    <b-col
      md="6"
      lg="4"
      xl="3"
      v-for="(item, id) in treatmentOptions"
      :key="item.id"
    >
      <b-form-group :label="item.name">
        <validation-provider #default="{ errors }" :name="item.name">
          <v-select
            v-model="treatmentProcedure[id].cant"
            :options="getOptionsWithPending(treatmentProcedure[id], pendingTreatments)"
            :reduce="(value) => value.value"
            :clearable="!item.promotion"
            @input="getOption(treatmentProcedure[id])"
            @open="handleSelectOpen"
            label="value"
          >
            <template #option="option">
              <div :class="{ 'pending-highlight': option && option.highlighted }">{{ option.value }}</div>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
				<small class="">{{ item.dosificacion}}</small>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { BRow, BCol, BFormGroup, BCard } from "bootstrap-vue";

import CheckConsent from "../notification/CheckConsent";
import CardStats from "../notification/CardStats";
import axiosA from "@/services/patient/appointment";

import vSelect from "vue-select";

export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    vSelect,
    CheckConsent,
    CardStats,
  },
  props: {
    treatmentProcedure: {
      type: Array,
      required: true,
    },
    treatmentOptions: {
      type: Array,
      required: true,
    },
    totalPriceTreatment: {
      type: Number,
      default: 0,
    },
    detail: {
      type: Object,
      required: true,
    },
    insuranceDetails: {
      type: Object,
      required: true,
    },
    totalPrice: {
      type: Number,
      required: true,
    },
    totalFinalWithInsurance: {
      type: Number,
      default: 0,
    },
    countUpdatePackage: {
      type: Number,
      required: true,
    },
    promotionOfficeVisit: {
      type: Object,
      required: true,
    },
    pendingTreatments: {
      type: Array,
      required: true
    }
  },
  mounted() {
    this.getOptions();
  },
  watch: {
    countUpdatePackage() {
      this.changePackage();
    },
  },
  data() {
    return {
      cantOptions: [
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: 10 },
      ],
      conditionCheckConsent: false,
      dataTreatment: null,
      changeIndex: null,
    };
  },
  methods: {
    getOptions() {
      this.treatmentOptions.forEach((element, index) => {
        if (element.promotion) {  
          const optionsTreatments = [];
          let cantPromo = 0
          if(this.promotionOfficeVisit.promotionTreatment){
            this.promotionOfficeVisit.promotionTreatment.forEach((item) => {
              if(item.id === element.id){
                cantPromo = item.pivot.cant_prom
              }
            })
          }
          const lengthPromotion = cantPromo
          for (let i = 1; i <= 10; i++) {
            if (i <= lengthPromotion) {
              optionsTreatments.push({
                value: i,
                promotion: true,
              });
            } else {
              optionsTreatments.push({
                value: i,
                promotion: false,
              });
            }
          }
          this.treatmentProcedure[index].options = optionsTreatments;
        } else {
          this.treatmentProcedure[index].options = this.cantOptions;
        }
      });
    },
    getOption(item) {
      this.addItem(item);

      if(item.cant >= 1) {
        this.changeIndex = item
      }else{
        this.changeIndex = null
      }
      if (item.informed_consent) {
        this.conditionCheckConsent = true;
        const user = this.detail;
        this.dataTreatment = { item, user };
      }
    },
    addInsurance() {
      const { totalDeductibleUsed, deductibleValue } = this.insuranceDetails;
      console.log('treatment', this.changeIndex)
      if (
        totalDeductibleUsed === deductibleValue &&
        this.totalPrice > totalDeductibleUsed
      ) {
        if(this.changeIndex !== null) {
          /* const paga = this.totalPrice - totalDeductibleUsed */
          const paga = this.changeIndex.price * this.changeIndex.cant
          console.log(paga, "dentro");
        }
      }
    },
    closeModalConset() {
      this.conditionCheckConsent = false;
      this.dataTreatment = null;
    },
    saveConsent(e) {
      const idOfficeVisit = this.$route.params.id;
      const { signature } = e;
      const data = {
        signature,
        treatments_id: this.dataTreatment.item.id,
      };
      axiosA.patientTreatmentConsent(idOfficeVisit, data).then(() => {});
    },
    addItem(item){
      if(item.cant >= 1){
        const pendingT = this.pendingTreatments.find(pending => pending.id === item.id && pending.pending === item.cant);
        const data = {
          id: item.id, 
          type: 1, 
          itemId: 8, 
          cant: item.cant, 
          total: pendingT ? (parseFloat(item.price) * (item.cant - pendingT.pending)) : (parseFloat(item.price) * item.cant)
          // total: parseFloat(item.price) * item.cant
        };
        this.$emit('calculateInsurance', data);
      }else{
        const data = {...item, type: 1, itemId: 8};
        this.$emit('calculateInsurance', data, 'delete');
      }
    },
    changePackage(){
      this.getOptions()
    },
    getOptionsWithPending(procedure, pendingOptions){
      const newOptions = procedure.options.map((option) => {
        return {
          ...option,
          highlighted: pendingOptions.some(pending => pending.id === procedure.id && pending.pending === option.value),
        }
      });
      return newOptions;
    },
    handleSelectOpen(){
      setTimeout(() => {
        const hijo = document.querySelector('.pending-highlight');
        if(hijo){
          const padre = hijo.closest('.vs__dropdown-option').classList.add('bg-pending');
        }
      }, 100);
    }
  }
};
</script>

<style>

.bg-pending{
  background: linear-gradient(140deg, #f1c500 0%, rgba(255, 255, 255, 0) 70%) !important;
}
</style>
