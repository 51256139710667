import { userAxios } from '@/services'

const testList = async (perPage) => {
    try {
        return await userAxios.get(`tests/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const testCreate = async (data) => {
    try {
        return await userAxios.post('tests', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const testUpdate = async (id, data) => {
    try {
        return await userAxios.put(`tests/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const testDelete = async (id) => {
    try {
        return await userAxios.delete(`tests/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const testPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`tests/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const testFilter = async (perPage, data ) => {
    try {
        return await userAxios.post(`tests/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const testFilterPagination = async (perPage, page, data) => {
    try {
        return await userAxios.post(`tests/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}


export default {
    testList,
    testCreate,
    testUpdate,
    testDelete,
    testPagination,
    testFilter,
    testFilterPagination
}