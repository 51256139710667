<template>
  <b-row class="mt-2">
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">Patient Details</h5>
      <small class="text-muted"> Enter patient details </small>
    </b-col>
    <b-col md="6">
      <b-form-group :label="$t('Form.Name')" label-for="name">
        <validation-provider #default="{ errors }" name="Name" rules="required">
          <b-form-input
            id="name"
            v-model="detail.name"
            :state="errors.length > 0 ? false : null"
            placeholder="Enter patient name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group :label="$t('Form.Last Name')" label-for="lastname">
        <validation-provider
          #default="{ errors }"
          name="lastname"
          rules="required"
        >
          <b-form-input
            id="lastname"
            v-model="detail.lastName"
            :state="errors.length > 0 ? false : null"
            placeholder="Enter patient last name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group :label="$t('Form.Phone')" label-for="phone">
        <validation-provider
          #default="{ errors }"
          name="Phone"
          rules="required"
        >
          <b-form-input
            id="phone"
            class="form-control"
            v-model="formatPhone"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group label="DOB" label-for="date_birth">
        <validation-provider
          #default="{ errors }"
          name="DOB"
          rules="required"
        >
          <flat-pickr
            id="date_birth"
            v-model="detail.dateBirth"
            :config="config"
            class="form-control"
            placeholder="MM-DD-YYYY"
            type="date"
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group label="Facility" label-for="facility">
        <validation-provider
          #default="{ errors }"
          name="Facility"
          rules="required"
        >
          <b-form-input
            id="facility"
            v-model="detail.facility"
            disabled
            :state="errors.length > 0 ? false : null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6" v-if="facilityOptions.length > 0">
      <validation-provider #default="{ errors }" name="Room" rules="required">
        <b-form-group
          :label="$t('Form.Room')"
          label-for="room"
          :state="errors.length > 0 ? false : null"
        >
          <v-select
            id="room"
            v-model="detail.room"
            :options="facilityOptions"
            @input="verifyRoom"
          />
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import axiosOV from "@/services/office-visit";
import { fromConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
    Cleave,
    flatPickr,
    ValidationProvider,
  },
  props: {
    detail: {
      type: Object,
      required: true,
    },
    facilityOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      config: fromConfig({ dateFormat: "m-d-Y", }),
    };
  },
  computed: {
    formatPhone: {
      get() {
        let { phone } = this.detail;
        phone = phone.toString().replace(/\D/g, "");
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
          phone = `(${match[1]}${match[2] ? "" : ""}) ${match[2]}${
            match[3] ? "-" : ""
          }${match[3]}`;
        }
        return phone;
      },
      set(val) {
        this.detail.phone = val;
      },
    },
  },
  methods: {
    verifyRoom() {
      const { room } = this.detail;
      axiosOV.roomsCheckAvailable(this.$route.params.id, room).then((res) => {
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>