<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="5">
        <b-form-group
          label="Acute Diseases"
          v-slot="{ ariaDescribedby }"
          label-class="h5 text-dark-75 font-weight-bolder font-size-sm mb-0"
        >
          <b-form-radio-group
            v-model="selected"
            :options="getAllChronic"
            :aria-describedby="ariaDescribedby"
            :disabled="packageSelected"
            name="radios-stacked"
            text-field="name"
            value-field="id"
            stacked
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col cols="5">
        <b-form-group
          label="Chronic diseases"
          label-class="text-dark-75 font-weight-bolder font-size-sm mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-form-radio-group
            v-model="selected"
            :options="getAllAcute"
            :aria-describedby="ariaDescribedby"
            :disabled="packageSelected"
            name="radios-stacked"
            text-field="name"
            value-field="id"
            stacked
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <PackageSelect
        v-if="isShowModalPackage"
        :packageById="packageSelected"
        :promotionOfficeVisit="promotionOfficeVisit"
        @closeModalPackage="closeModalPackage"
        @onChangePackage="addPackage"
        @onChangePackageV2="onChangePackageV2"
      />
      <ToastNotification ref="toast" />
    </b-row>
    <b-row>
      <b-col cols="5">
        <div class="d-flex">
          <v-select
            id="mySelect"
            class="w-100"
            v-model="packageSelectedMutated"
            :options="optionsPackages"
            :reduce="(val) => val.id"
            @input="changePackageSelected"
            label="name"
          />
          <b-button
            v-if="packageSelected"
            variant="primary"
            class="btn-icon"
            @click="isShowModalPackage = true"
          >
            <feather-icon icon="EditIcon" size="15" />
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-img fluid :src="tableImage"></b-img>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormRadioGroup,
  BTable,
  BCard,
  BImg,
} from "bootstrap-vue";
import axiosPackages from "@/services/office-visit/package";
import axiosPackageCategories from "@/services/office-visit/packageCategories";
import vSelect from "vue-select";

import PackageSelect from "../notification/PackageSelect";

import ToastNotification from "@/components/ToastNotification";
import { notificationCheck } from "@/mixins/NotificationSetup";
import { isNumber } from "@vueuse/core";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BTable,
    BCard,
    BImg,

    vSelect,

    PackageSelect,
    ToastNotification,
  },
  props: {
    idOfficeVisit: {
      type: [String, Number],
      required: true,
    },
    promotionOfficeVisit: {
      type: Object,
      required: true,
    },
    packageSelected: {
      type: Number,
      default: null,
    },
    countUpdatePackage: {
      type: Number,
      required: true,
    },
    categorySelectedPackage: {
      type: Number,
      required: false,
    },
  },
  mounted() {
    this.getPackages();
  },
  data() {
    return {
      optionsPackages: [],
      isShowModalPackage: false,
      selectInPackage: null,
      categoriesPackage: [],
      selected: null,
      tableImage: null,
      packageSelectedMutated: null,
    };
  },
  watch: {
    selected(newValue) {
      this.tableImage = this.categoriesPackage.filter(
        (item) => item.id == newValue
      )[0].img_table;
      this.getTablePackage(newValue);
    },
    categorySelectedPackage() {
      this.selected = this.categorySelectedPackage;
    },
    packageSelected() {
      this.packageSelectedMutated = this.packageSelected;
    },
  },
  computed: {
    getAllChronic() {
      return this.categoriesPackage.filter(
        (item) => item.type_of_disease.id === 1
      );
    },
    getAllAcute() {
      return this.categoriesPackage.filter(
        (item) => item.type_of_disease.id === 2
      );
    },
  },
  methods: {
    getPackages() {
      axiosPackageCategories.packageCategorieList().then(({ registro }) => {
        this.categoriesPackage = registro;
      });
      /* axiosPackages.packagesList(10).then(({ data }) => {
        this.optionsPackages = data;
      }); */
    },
    getTablePackage(value) {
      axiosPackageCategories.packageByCategories(value).then(({ registro }) => {
        this.optionsPackages = registro;
      });
    },
    onChangePackageV2(value) {
      this.selectInPackage = value;
      axiosPackages
        .packageAddInAppointment(this.idOfficeVisit, value)
        .then((res) => {
          this.addPackage(res.registro);
          const { type, message } = notificationCheck(res);
          this.$refs.toast[type](message);
        });
    },
    changePackageSelected(value) {
      if (value) {
        this.isShowModalPackage = true;
      } else {
        axiosPackages
          .packageDeleteInAppointment(this.idOfficeVisit)
          .then((res) => {
            this.addPackage(res.registro);
            const { type, message } = notificationCheck(res);
            this.$refs.toast[type](message);
          });
      }
      this.$emit("changePackageSelected", value);
    },
    closeModalPackage() {
      this.isShowModalPackage = false;
    },
    addPackage(data) {
      if (data) {
        const {
          procedures,
          laboratories,
          diagnostic_imagings,
          treatments,
          tests,
          appointments: {
            clinical_package_id,
            clinical_package,
            consult_prices,
          },
        } = data;

        this.promotionOfficeVisit.promotionProcedure = procedures;
        this.promotionOfficeVisit.laboratoryPromotion = laboratories;
        this.promotionOfficeVisit.promotionImaging = diagnostic_imagings;
        this.promotionOfficeVisit.promotionTest = tests;
        this.promotionOfficeVisit.promotionTreatment = treatments;
        this.$emit("changeTreatments", treatments);

        const count = this.countUpdatePackage + 1;

        const dataProcedure = {
          clinical_package_id,
          price: clinical_package && clinical_package.price,
          consult: consult_prices && consult_prices.price,
          countUpdatePackage: count,
        };
        this.$emit("changePackageOfficeVisit", dataProcedure);
      }
    },
    parseLabels(value) {
      if (isNumber(value)) {
        return value === 0 ? "Free" : `$ ${value}`;
      }
      if (value === null) {
        return "Not included";
      }
      return value;
    },
  },
};
</script>

<style></style>
