var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-lg","hide-footer":"","hide-header":"","centered":"","no-close-on-backdrop":"","size":"lg"},on:{"hide":_vm.onHide},model:{value:(_vm.conditionPackage),callback:function ($$v) {_vm.conditionPackage=$$v},expression:"conditionPackage"}},[(_vm.dataPackage)?_c('b-card',{staticClass:"card-browser-states shadow-none mb-0",attrs:{"no-body":""}},[_c('b-card-header',[_c('div',[_c('b-card-title',[_vm._v(_vm._s(_vm.dataPackage.name))])],1)]),_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","rounded":"lg"}}),_c('b-card-body',[_c('div',{staticClass:"d-flex align-items-end"},[_c('h4',{staticClass:"font-weight-bold mr-1"},[_vm._v("Package Price:")]),_c('h4',{staticClass:"font-weight-bolder"},[_vm._v("$"+_vm._s(_vm.dataPackage.price))])]),_c('div',{staticClass:"d-flex align-items-end"},[_c('h5',{staticClass:"font-weight-bold mr-1"},[_vm._v("Regular price:")]),_c('h5',{staticClass:"font-weight-bolder"},[_vm._v("$"+_vm._s(_vm.dataPackage.regular_price))])]),_c('div',{staticClass:"d-flex align-items-end"},[_c('h5',{staticClass:"font-weight-bold mr-1"},[_vm._v("Discount:")]),_c('h5',{staticClass:"font-weight-bolder"},[_vm._v("$"+_vm._s(_vm.dataPackage.discount))])]),_c('div',[_c('span',{staticClass:"font-weight-bold text-body-heading mr-1"},[_vm._v("Description:")]),_vm._v(" "+_vm._s(_vm.dataPackage.description)+" ")]),_c('validation-observer',{ref:"packageRef"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.addPackage.apply(null, arguments)}}},[_c('b-row',{staticClass:"mt-2"},[(_vm.dataPackage.laboratories.length > 0)?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Laboratories","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Laboratories","label-for":"room","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"room","options":_vm.dataPackage.laboratories,"reduce":function (value) { return value.id; },"selectable":function () { return _vm.packageSelected.laboratories.length < _vm.limitLaboratory; },"multiple":"","label":"name"},model:{value:(_vm.packageSelected.laboratories),callback:function ($$v) {_vm.$set(_vm.packageSelected, "laboratories", $$v)},expression:"packageSelected.laboratories"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,2691674589)})],1):_vm._e(),(_vm.dataPackage.laboratories_pack.length > 0)?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"laboratories pack","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Laboratories pack","label-for":"pack","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"pack","options":_vm.dataPackage.laboratories_pack,"reduce":function (value) { return value.id; },"multiple":"","label":"name"},model:{value:(_vm.packageSelected.laboratories_pack),callback:function ($$v) {_vm.$set(_vm.packageSelected, "laboratories_pack", $$v)},expression:"packageSelected.laboratories_pack"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,177735096)})],1):_vm._e(),(_vm.dataPackage.procedures.length > 0)?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Procedures","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Procedures","label-for":"procedure","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"procedure","options":_vm.dataPackage.procedures,"reduce":function (value) { return value.id; },"selectable":function () { return _vm.packageSelected.procedures < _vm.limitProcedure; },"multiple":"","label":"name"},model:{value:(_vm.packageSelected.procedures),callback:function ($$v) {_vm.$set(_vm.packageSelected, "procedures", $$v)},expression:"packageSelected.procedures"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3260154827)})],1):_vm._e(),(_vm.dataPackage.diagnostic_imagings.length > 0)?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Diagnostic imagings","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Diagnostic imagings","label-for":"room","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"room","options":_vm.dataPackage.diagnostic_imagings,"reduce":function (value) { return value.id; },"selectable":function () { return _vm.packageSelected.diagnostic_imagings < _vm.limitImaging; },"multiple":"","label":"name"},model:{value:(_vm.packageSelected.diagnostic_imagings),callback:function ($$v) {_vm.$set(_vm.packageSelected, "diagnostic_imagings", $$v)},expression:"packageSelected.diagnostic_imagings"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,741928155)})],1):_vm._e(),(_vm.dataPackage.tests.length > 0)?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Test","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Test","label-for":"room","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"room","options":_vm.dataPackage.tests,"reduce":function (value) { return value.id; },"selectable":function () { return _vm.packageSelected.tests.length < _vm.limitTest; },"multiple":"","label":"name"},model:{value:(_vm.packageSelected.tests),callback:function ($$v) {_vm.$set(_vm.packageSelected, "tests", $$v)},expression:"packageSelected.tests"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3727014769)})],1):_vm._e(),(_vm.dataPackage.treatments.length > 0)?_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Treatments"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Treatments","label-for":"room","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"room","options":_vm.dataPackage.treatments,"multiple":"","selectable":function () { return _vm.cantTreatment < _vm.limitTreatment; },"label":"name"},model:{value:(_vm.packageSelected.treatments),callback:function ($$v) {_vm.$set(_vm.packageSelected, "treatments", $$v)},expression:"packageSelected.treatments"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1754511459)})],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex flex-column w-100"},_vm._l((_vm.packageSelected.treatments),function(item,index){return _c('div',{key:index,staticClass:"d-flex w-100"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":""},domProps:{"value":item.name}}),_c('validation-provider',{attrs:{"name":"Items treatments","rules":{
                      required: true,
                      min_value: 1,
                      limitTreatmentRules: {
                        cantTreatment: _vm.cantTreatment,
                        limitTreatment: _vm.limitTreatment,
                      },
                    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.cant),expression:"item.cant"}],staticClass:"form-control",attrs:{"type":"number","max":"10","min":"1"},domProps:{"value":(item.cant)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(item, "cant", $event.target.value)}}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)}),0)])],1),_c('div',{staticClass:"d-flex justify-content-end mt-2"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"button","variant":"outline-danger"},on:{"click":_vm.onHide}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.loading}},[(!_vm.loading)?_c('span',[_vm._v("Apply")]):_c('span',[_c('SpinnerLoading')],1)])],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }