import { userAxios } from '../index'

const packagesList = async (pagination) => {
    try {
        return await userAxios.get(`procedure-packages/index/${pagination}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageAddInAppointment = async (officeVisitId, data) => {
    try {
        return await userAxios.put(`/office-visit/apply-package/${officeVisitId}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageDeleteInAppointment = async (officeVisitId) => {
    try {
        return await userAxios.put(`/office-visit/detach-package/${officeVisitId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}


export default {
    packagesList,
    packageAddInAppointment,
    packageDeleteInAppointment
}