<template>
  <b-modal
    id="modal-lg"
    v-model="conditionPackage"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
    size="lg"
    @hide="onHide"
  >
    <b-card
      no-body
      class="card-browser-states shadow-none mb-0"
      v-if="dataPackage"
    >
      <b-card-header>
        <div>
          <b-card-title>{{ dataPackage.name }}</b-card-title>
        </div>
      </b-card-header>

      <!-- body -->
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="lg"
      ></b-overlay>
      <b-card-body>
        <!-- general info -->
        <div class="d-flex align-items-end">
          <h4 class="font-weight-bold mr-1">Package Price:</h4>
          <h4 class="font-weight-bolder">${{ dataPackage.price }}</h4>
        </div>

        <div class="d-flex align-items-end">
          <h5 class="font-weight-bold mr-1">Regular price:</h5>
          <h5 class="font-weight-bolder">${{ dataPackage.regular_price }}</h5>
        </div>
        <div class="d-flex align-items-end">
          <h5 class="font-weight-bold mr-1">Discount:</h5>
          <h5 class="font-weight-bolder">${{ dataPackage.discount }}</h5>
        </div>

        <div>
          <span class="font-weight-bold text-body-heading mr-1"
            >Description:</span
          >
          {{ dataPackage.description }}
        </div>

        <validation-observer ref="packageRef">
          <b-form class="auth-login-form mt-2" @submit.prevent="addPackage">
            <b-row class="mt-2">
              <!-- laboratories -->
              <b-col md="12" v-if="dataPackage.laboratories.length > 0">
                <validation-provider
                  #default="{ errors }"
                  name="Laboratories"
                  rules=""
                >
                  <b-form-group
                    label="Laboratories"
                    label-for="room"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="room"
                      v-model="packageSelected.laboratories"
                      :options="dataPackage.laboratories"
                      :reduce="(value) => value.id"
                      :selectable="
                        () =>
                          packageSelected.laboratories.length < limitLaboratory
                      "
                      multiple
                      label="name"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="12" v-if="dataPackage.laboratories_pack.length > 0">
                <validation-provider
                  #default="{ errors }"
                  name="laboratories pack"
                  rules=""
                >
                  <b-form-group
                    label="Laboratories pack"
                    label-for="pack"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="pack"
                      v-model="packageSelected.laboratories_pack"
                      :options="dataPackage.laboratories_pack"
                      :reduce="(value) => value.id"
                      multiple
                      label="name"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="12" v-if="dataPackage.procedures.length > 0">
                <validation-provider
                  #default="{ errors }"
                  name="Procedures"
                  rules=""
                >
                  <b-form-group
                    label="Procedures"
                    label-for="procedure"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="procedure"
                      v-model="packageSelected.procedures"
                      :options="dataPackage.procedures"
                      :reduce="(value) => value.id"
                      :selectable="
                        () => packageSelected.procedures < limitProcedure
                      "
                      multiple
                      label="name"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- diagnostic_imagings -->

              <b-col md="12" v-if="dataPackage.diagnostic_imagings.length > 0">
                <validation-provider
                  #default="{ errors }"
                  name="Diagnostic imagings"
                  rules=""
                >
                  <b-form-group
                    label="Diagnostic imagings"
                    label-for="room"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="room"
                      v-model="packageSelected.diagnostic_imagings"
                      :options="dataPackage.diagnostic_imagings"
                      :reduce="(value) => value.id"
                      :selectable="
                        () => packageSelected.diagnostic_imagings < limitImaging
                      "
                      multiple
                      label="name"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- tests -->

              <b-col md="12" v-if="dataPackage.tests.length > 0">
                <validation-provider #default="{ errors }" name="Test" rules="">
                  <b-form-group
                    label="Test"
                    label-for="room"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="room"
                      v-model="packageSelected.tests"
                      :options="dataPackage.tests"
                      :reduce="(value) => value.id"
                      :selectable="
                        () => packageSelected.tests.length < limitTest
                      "
                      multiple
                      label="name"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- treatments -->

              <b-col md="12" v-if="dataPackage.treatments.length > 0">
                <validation-provider #default="{ errors }" name="Treatments">
                  <b-form-group
                    label="Treatments"
                    label-for="room"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      id="room"
                      v-model="packageSelected.treatments"
                      :options="dataPackage.treatments"
                      multiple
                      :selectable="() => cantTreatment < limitTreatment"
                      label="name"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="12">
                <div class="d-flex flex-column w-100">
                  <div
                    v-for="(item, index) in packageSelected.treatments"
                    :key="index"
                    class="d-flex w-100"
                  >
                    <input
                      type="text"
                      class="form-control"
                      :value="item.name"
                      disabled
                    />
                    <validation-provider
                      #default="{ errors }"
                      name="Items treatments"
                      :rules="{
                        required: true,
                        min_value: 1,
                        limitTreatmentRules: {
                          cantTreatment: cantTreatment,
                          limitTreatment: limitTreatment,
                        },
                      }"
                    >
                      <input
                        type="number"
                        class="form-control"
                        v-model="item.cant"
                        max="10"
                        min="1"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                </div>
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end mt-2">
              <b-button
                type="button"
                class="mr-1"
                variant="outline-danger"
                @click="onHide"
              >
                {{ $t("Cancel") }}
              </b-button>
              <b-button variant="primary" type="submit" :disabled="loading">
                <span v-if="!loading">Apply</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
      <!--/ body -->
    </b-card>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BFormGroup,
  BAvatar,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BImg,
  BOverlay,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BForm
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";

import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import moment from "moment";

import axiosPC from "@/services/admin/ProcedurePackages.js";

extend("limitTreatmentRules", {
  params: ["cantTreatment", "limitTreatment"],
  validate: (value, { cantTreatment, limitTreatment }) => {
    console.log();
    if (cantTreatment <= limitTreatment) {
      return true;
    } else {
      return false;
    }
  },
  message: (field, { limitTreatment }) =>
    `El ${field} must be higher or greater to ${limitTreatment}`,
});

export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BAvatar,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BImg,
    BOverlay,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BForm,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
    vSelect,
  },
  watch: {
    "packageSelected.treatments": {
      handler() {
        const data = this.packageSelected.treatments;
        this.cantTreatment = data.reduce((acumulador, objeto) => {
          const cantidad = parseInt(objeto.cant, 10) || 0; // Convertir "cant" a número
          return acumulador + cantidad;
        }, 0);
      },
      deep: true,
    },
  },
  data() {
    return {
      conditionPackage: true,
      dataPackage: null,
      dataPackageData: [],
      loading: false,
      discount: 0,
      packageSelected: {
        clinical_package_id: this.packageById,
        procedures: [],
        laboratories: [],
        laboratories_pack: [],
        tests: [],
        diagnostic_imagings: [],
        treatments: [],
      },
      limitTreatment: 0,
      limitLaboratory: 0,
      limitTest: 0,
      limitProcedure: 0,
      limitImaging: 0,
      cantTreatment: 0,
    };
  },
  props: {
    packageById: {
      type: Number,
      required: true,
    },
    promotionOfficeVisit: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.getPackage();
    this.getVariableForms();
  },
  computed: {
    validateTotal() {},
  },
  methods: {
    onHide() {
      this.$emit("closeModalPackage", false);
    },
    getPackage() {
      this.loading = true;
      axiosPC
        .packageView(this.packageById)
        .then(({ registro }) => {
          this.dataPackage = registro;

          const propertiesToExtract = {
            treatments: "limitTreatment",
            laboratories: "limitLaboratory",
            tests: "limitTest",
            procedures: "limitProcedure",
            diagnostic_imagings: "limitImaging",
          };

          Object.entries(propertiesToExtract).forEach(([property, target]) => {
            this[target] = registro[property]?.[0]?.pivot.limit;
          });

          this.loading = false;
        })
        .catch((error) => {
          console.error("Error en la solicitud HTTP:", error);
          this.loading = false;
        });
    },
    formatDate(date) {
      return moment(date).calendar();
    },
    getTotalPrice(price, quantity) {
      return (price * Number(quantity)).toString();
    },
    addPackage() {
      this.$refs.packageRef.validate().then(async (success) => {});
      this.$emit("onChangePackageV2", this.packageSelected);
      this.onHide();
    },
    getVariableForms() {
      const variables = {
        laboratoryPromotion: "laboratories",
        promotionImaging: "diagnostic_imagings",
        promotionProcedure: "procedures",
        promotionTest: "tests",
      };

      // Loop through the original data
      for (const key in this.promotionOfficeVisit) {
        if (
          this.promotionOfficeVisit.hasOwnProperty(key) &&
          variables.hasOwnProperty(key)
        ) {
          // Determine the target array based on the variable mapping
          const targetArray = this.packageSelected[variables[key]];

          // Check if the original data has items for the current key
          if (this.promotionOfficeVisit[key].length > 0) {
            // Map the relevant data and push it to the target array
            this.promotionOfficeVisit[key].forEach((item) => {
              targetArray.push(item.id);
            });
          }
        }
      }

      this.packageSelected.treatments = this.promotionOfficeVisit.promotionTreatment.map((item) => {
        return {
          id: item.id,
          name: item.name,
          cant: item.pivot.cant
        }
      })
      
    },
  },
};
</script>

<style lang="scss"></style>
