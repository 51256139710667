var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Charges and Costs")]),_c('small',{staticClass:"text-muted"},[_vm._v("Enter the costs of the procedure")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Consultation","label-for":"consultation"}},[_c('validation-provider',{attrs:{"name":"Consultation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isInsurance)?_c('b-form-input',{attrs:{"id":"consultation","disabled":"","state":errors.length > 0 ? false : null},model:{value:(_vm.consultation.price),callback:function ($$v) {_vm.$set(_vm.consultation, "price", $$v)},expression:"consultation.price"}}):_c('v-select',{attrs:{"id":"consultation","options":_vm.visitRates,"label":"value"},on:{"input":function($event){return _vm.addItem($event)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex"},[_vm._v(" Valor: "+_vm._s(option.value)+" - Nivel: "+_vm._s(option.level)+" ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"d-flex"},[_vm._v(" Valor: "+_vm._s(option.value)+" - Nivel: "+_vm._s(option.level)+" ")])]}}]),model:{value:(_vm.consultation.price),callback:function ($$v) {_vm.$set(_vm.consultation, "price", $$v)},expression:"consultation.price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Diagnostic imagings","label-for":"diagnostic-imagings"}},[_c('validation-provider',{attrs:{"name":"Diagnostic imagings","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"diagnostic-imagings","disabled":"","value":_vm.totalPriceDiagnosticImaging,"state":errors.length > 0 ? false : null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Subtotal","label-for":"subtotal"}},[_c('validation-provider',{attrs:{"name":"Subtotal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"subtotal","value":_vm.subtotalPrice,"disabled":"","state":errors.length > 0 ? false : null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Adjustment","label-for":"adjustment"}},[_c('validation-provider',{attrs:{"name":"Adjustment","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isDisabledAdjusment && _vm.isInsuranceFacility == false,"id":"adjustment","state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.$emit('totalAdjustment', _vm.getCostProcedure.adjusment)}},model:{value:(_vm.getCostProcedure.adjusment),callback:function ($$v) {_vm.$set(_vm.getCostProcedure, "adjusment", _vm._n($$v))},expression:"getCostProcedure.adjusment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Total","label-for":"total"}},[_c('validation-provider',{attrs:{"name":"Total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"total","disabled":"","value":_vm.totalPrice,"state":errors.length > 0 ? false : null}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12","xl":"6"}},[_vm._t("default")],2),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-checkbox',{model:{value:(_vm.getCostProcedure.authorization),callback:function ($$v) {_vm.$set(_vm.getCostProcedure, "authorization", $$v)},expression:"getCostProcedure.authorization"}},[_vm._v(" IM "+_vm._s(_vm.detailsPatient.name)+" "+_vm._s(_vm.detailsPatient.lastName)+" Accept terms and conditions explained by the medical team of NORTH TEXAS TELEMEDICINE facility Clinica La Virgen de Guadalupe ")])],1)],1)],1),(_vm.conditionOverrun)?_c('CostOverrun'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }