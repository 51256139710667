<template>
  <b-sidebar
    id="add-new-medication-sidebar"
    :visible="isAddNewMedicationUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-new-medication-user-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <MedicationAdd
        :is-add-new-medication-sidebar-active.sync="
          isAddNewMedicationSidebarActive
        "
        @createMedication="createMedication"
      />
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ $t("Add") }}  {{ $t('New') }} Medication</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-button
            size="sm"
            variant="success"
            class="btn-icon mb-1"
            @click="isAddNewMedicationSidebarActive = true"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Medication"
            rules="required"
          >
            <b-form-group label="Medication" label-for="name">
              <v-select
                id="category"
                v-model="medication.medication"
                @search="searchMedications"
                :state="getValidationState(validationContext)"
                :options="optionsMedications.data"
                :clearable="false"
                :reduce="(value) => value.id"
                label="name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- start Date -->
          <validation-provider #default="validationContext" name="start_date">
            <b-form-group label="Start Date" label-for="start_date">
              <flat-pickr
                id="date_birth"
                v-model="medication.startDate"
                :config="config"
                class="form-control"
                placeholder="MM-DD-YYYY"
                type="date"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Category -->
          <validation-provider #default="validationContext" name="Stop Date">
            <b-form-group label="Stop Date" label-for="stop_date">
              <flat-pickr
                id="date_birth"
                v-model="medication.stopDate"
                :config="config"
                class="form-control"
                placeholder="MM-DD-YYYY"
                type="date"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="Note" rules="">
            <b-form-group label="Note" label-for="note">
              <b-form-textarea
                id="note"
                v-model="medication.note"
                placeholder="Note"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select
                id="status"
                v-model="medication.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :clearable="false"
                label="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">{{ $t("Add") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { onMounted, ref } from "@vue/composition-api";

import MedicationAdd from "@/views/admin/type-consult/medication/MedicationAdd.vue";

import axiosM from "@/services/office-visit/medications";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,
    vSelect,
    flatPickr,

    MedicationAdd,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewMedicationUserSidebarActive",
    event: "update:is-add-new-medication-user-sidebar-active",
  },
  props: {
    isAddNewMedicationUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    medicalHistory: {
      type: Object,
      required: true,
    },
    optionsMedications: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const isAddNewMedicationSidebarActive = ref(false);
    const config = {
      altInput: true,
      altFormat: "m-d-Y",
      dateFormat: "m-d-Y",
      allowInput: true,
    };

    const optionsStatus = [
      { text: "Taking", value: 1 },
      { text: "Not Taking", value: 2 },
      { text: "Discontinued", value: 3 },
      { text: "Unknown Status", value: 4 },
    ];

    const stateDataTem = {
      medication: "",
      startDate: null,
      stopDate: null,
      note: null,
      status: null,
    };
    const medication = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetuserData = () => {
      medication.value = JSON.parse(JSON.stringify(stateDataTem));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const createMedication = () => {
      isAddNewMedicationSidebarActive.value = false;
      emit("createMedication", true);
      getMedications()
    };

    const getMedications = () => {
      axiosM
        .paientMedicationInformation(props.medicalHistory.userId, data)
        .then(({ registro: { data } }) => {
          props.optionsMedications.data = data;
        });
    };

    const clearTimeoutBuscador = ref("");
    const searchMedications = (name, loading) => {
      if (name.length) {
        const data = {
          name,
        };
        loading(true);
        clearTimeout(clearTimeoutBuscador.value);
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosM
            .paientMedicationFilter(props.medicalHistory.userId, data)
            .then(({ registro: { data } }) => {
              loading(false);
              props.optionsMedications.data = data;
            });
        }, 400);
      }
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const datos = {
        history_id: parseInt(props.medicalHistory.historyId),
        new_medications: [
          {
            medications_id: medication.value.medication,
            start_date: medication.value.startDate,
            start_stop: medication.value.stopDate,
            note: medication.value.note,
            status_medications_id: medication.value.status.value,
          },
        ],
      };
      axiosM
        .paientMedicationCreate(datos)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("createMedicationUser", true);
        })
        .catch(() => {
          loading.value = false;
          emit("createMedicationUser", false);
        });
    };
    return {
      loading,
      config,
      medication,
      optionsStatus,
      onSubmit,
      searchMedications,
      refFormObserver,
      getValidationState,
      resetForm,
      createMedication,
      isAddNewMedicationSidebarActive,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>