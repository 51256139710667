<template>
  <div>
    <b-row class="mt-2">
      <b-col class="mb-2">
        <h5 class="mb-0">Diagnostic Imaging</h5>
        <small class="text-muted">Enter the patient's diagnostic images.</small>
      </b-col>
      <b-col>
        <CardStats
          :totalPriceSection="totalPriceDiagnosticImaging"
          :totalPrice="totalPrice"
          :totalPriceWithInsurance="totalFinalWithInsurance"
          :insuranceDetails="insuranceDetails"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" v-if="promotionOfficeVisit.promotionImaging.length > 0" class="mb-1">
        <label for="">Promotions</label>
        <b-form-group
        >
          <v-select
            v-if="!isCheckPromotion"
            :components="{ Deselect }"
            id="mySelect"
            v-model="promotionOfficeVisit.promotionImaging"
            multiple
            :noDrop="true"
            :searchable="false"
            label="name"
          ></v-select>
          <v-select
            v-else
            id="mySelect"
            v-model="promotionOfficeVisit.promotionImaging"
            multiple
            :noDrop="true"
            label="name"
          ></v-select>
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        lg="6"
        xl="4"
        class="my-1"
        v-for="item in diagnosticLength.data"
        :key="item.id"
      >
        <b-form-group :label-for="item.name">
          <div class="d-flex justify-content-between">
            <label for="">{{ item.name }}</label>
            <div
              ref=""
              v-for="(item, i) in validResultsList(
                diagnosticSelected[item.name]
              )"
              :key="i"
            >
              <b-button
                v-if="item.status && item.names"
                variant="flat-warning"
                class="btn-icon rounded-circle p-0"
                v-b-tooltip.hover.top="
                  `El procedimiento ${item.names} no fue realizado, por favor actualizar orden`
                "
              >
                <feather-icon icon="DeleteIcon" />
              </b-button>
            </div>
          </div>
          <validation-provider #default="{ errors }" :name="item.name">
            <v-select
              id="mySelect"
              v-model="diagnosticSelected[item.name]"
              :options="item.diagnosticM"
              multiple
              @option:selected="addItem($event)"
              @option:deselected="addItem($event)"
              label="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BFormGroup,
  BButton,
  BCard,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";

import CardStats from "../notification/CardStats";

import axiosIC from "@/services/admin/consults/diagnosticImagesCategories";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    vSelect,

    CardStats,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    diagnosticSelected: {
      type: Object,
      required: true,
    },
    diagnosticLength: {
      type: Object,
      required: true,
    },
    totalPriceDiagnosticImaging: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
    insuranceDetails: {
      type: Object,
      required: true,
    },
    totalFinalWithInsurance: {
      type: Number,
      default: 0,
    },
    isPackageOfficeVisit: {
      type: Number,
      default: null,
    },
    promotionOfficeVisit: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isPackageOfficeVisit() {
      this.changePackage();
    },
  },
  data() {
    return {
      Deselect: {
        render: (createElement) => createElement("span"),
      },
      isCheckPromotion: false,
    };
  },
  methods: {
    getDiagnosticCategory() {
      axiosIC.diagnosticImageCategoryList(20).then(({ data }) => {
        const catImaging = data.map(({ diagnostic_imagings, ...res }) => {
          const diagnosticM = diagnostic_imagings.filter(
            (laboratory) => laboratory.price != null
          );
          return {
            diagnosticM,
            ...res,
          };
        });
        this.diagnosticLength.data = catImaging;
      });
    },
    validResultsList(item) {
      if (item != undefined) {
        const names = [];
        item.forEach((element) => {
          if (element.pivot && element.pivot.status === false) {
            names.push(element.name);
          }
        });
        if (names.length > 0) {
          return [
            {
              status: true,
              names: names.join(", "),
            },
          ];
        } else {
          return item;
        }
      }
      return item;
    },
    addItem(item) {
      if (Array.isArray(item)) {
        /* add element */
        const data = {
          id: item[item.length - 1].id,
          type: 2,
          itemId: 9,
          total: item[item.length - 1].price,
        };
        this.$emit("calculateInsurance", data);
      } else {
        /* delete element */
        const data = { ...item, type: 2, itemId: 9 };
        this.$emit("calculateInsurance", data, "delete");
      }
    },
    changePackage() {
      if (this.isPackageOfficeVisit) {
        this.isCheckPromotion = true;
      } else {
        this.isCheckPromotion = false;
      }
    },
  },
};
</script>
<style scoped>
#mySelect >>> .vs__dropdown-menu {
  min-width: 350px;
}
</style>
