import { userAxios } from '@/services'

const medicationList = async (perPage) => {
    try {
        return await userAxios.get(`medications/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const medicationCreate = async (data) => {
    try {
        return await userAxios.post('medications', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const medicationUpdate = async (id, data) => {
    try {
        return await userAxios.put(`medications/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const medicationDelete = async (id) => {
    try {
        return await userAxios.delete(`medications/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const medicationPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`medications/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const medicationFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`medications/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const medicationFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`medications/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    medicationList,
    medicationCreate,
    medicationUpdate,
    medicationDelete,
    medicationPagination,
    medicationFilter,
    medicationFilterPagination
}