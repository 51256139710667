import { userAxios } from '@/services'

const laboratoryList = async (paginate) => {
    try {
        return await userAxios.get(`laboratory/index/${paginate}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryCreate = async (data) => {
    try {
        return await userAxios.post('laboratory', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryUpdate = async (id, data) => {
    try {
        return await userAxios.put(`laboratory/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryDelete = async (id) => {
    try {
        return await userAxios.delete(`laboratory/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`laboratory/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`laboratory/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const laboratoryFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`laboratory/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    laboratoryList,
    laboratoryCreate,
    laboratoryUpdate,
    laboratoryDelete,
    laboratoryPagination,
    laboratoryFilter,
    laboratoryFilterPagination
}