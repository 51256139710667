<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Assessment</h5>
        <small class="text-muted">Enter Your Medical History.</small>
      </b-col>
      <b-col md="6">
        <validation-provider #default="{ errors }" name="PMH">
          <b-form-group
            label="PMH"
            label-for="pmh"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              id="pmh"
              v-model="medicalHistory.ailments"
              @search="searchAilment"
              @input="changeVariable"
              :options="ailmentOptions.data"
              multiple
              label="name"
            />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <validation-provider #default="{ errors }" name="Examination">
          <b-form-group
            label="Examination"
            label-for="examination"
            :state="errors.length > 0 ? false : null"
          >
            <div>
              <b-form-radio-group
                id="examination"
                v-model="assessmentProcedure.examination"
                :options="options"
                class="demo-inline-spacing mb-1"
                value-field="value"
                text-field="text"
                disabled-field="disabled"
              />
            </div>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            v-if="assessmentProcedure.examination == 'abnormal'"
            label="Describe positive"
            label-for="textarea-positive"
          >
            <b-form-textarea
              id="textarea-positive"
              v-model="assessmentProcedure.describeAbnormal"
              placeholder="Describe positive"
              no-resize
              rows="3"
            />
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col md="6">
        <validation-provider #default="{ errors }" name="Diagnoses">
          <b-form-group
            label-for="diagnoses"
            label="Diagnoses"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              id="diagnoses"
              v-model.trim="assessmentProcedure.diagnoses"
              @search="searchDiagnosis"
              @input="changeVariable"
              :options="diagnosesOptions.data"
              multiple
              label="name"
            >
              <template #no-options="{ search, searching }">
                <NewItemSelect
                  v-if="searching"
                  :search="search"
                  @onSaveVisitReason="onSaveDiagnosis(search)"
                />
              </template>
            </v-select>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <ul>
          <li
            v-for="diagnoses in assessmentProcedure.diagnoses"
            :key="diagnoses.id"
          >
            {{ diagnoses.name }}
          </li>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormRadioGroup,
  BFormTextarea,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import NewItemSelect from "@/components/NewItemSelect";

import axiosAI from "@/services/admin/consults/ailments";
import axiosD from "@/services/admin/consults/diagnoses";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormRadioGroup,
    BFormTextarea,
    BFormInvalidFeedback,
    vSelect,
    NewItemSelect,
  },
  props: {
    medicalHistory: {
      type: Object,
      required: true,
    },
    assessmentProcedure: {
      type: Object,
      default: "",
    },
    ailmentOptions: {
      type: Object,
      required: true,
    },
    diagnosesOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: [
        { text: "Normal", value: "normal", disabled: false },
        { text: "Abnormal", value: "abnormal", disabled: false },
      ],
      setTimeoutBuscador: null,
    };
  },
  methods: {
    getAilments() {
      axiosAI.ailmentList(10).then(({ data }) => {
        this.ailmentOptions.data = data;
      });
    },
    getDiagnoses() {
      axiosD.diagnosesList(10).then(({ data }) => {
        this.diagnosesOptions.data = data;
      });
    },
    searchAilment(ailment, loading) {
      if (ailment.length) {
        loading(true);
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: ailment,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosAI.ailmentFilter(10, datos).then(({ data }) => {
            loading(false);
            this.ailmentOptions.data = data;
          });
        }, 350);
      }
    },
    searchDiagnosis(diagnosis, loading) {
      if (diagnosis.length) {
        loading(true);
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: diagnosis,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosD.diagnosesFilter(10, datos).then(({ data }) => {
            loading(false);
            this.diagnosesOptions.data = data;
          });
        }, 350);
      }
    },
    changeVariable() {
      this.$emit("changeHistory", true);
    },
    onSaveDiagnosis(name) {
      const data = {
        name,
      };
      axiosD.diagnosesCreate(data).then((registro) => {
        this.assessmentProcedure.diagnoses.push(registro);
        this.diagnosesOptions.data = [registro];
      });
    },
  },
};
</script>

<style></style>
