<template>
  <b-row class="mt-2">
    <MedicationAddUser
      v-if="isAddNewMedicationUserSidebarActive"
      :is-add-new-medication-user-sidebar-active.sync="
        isAddNewMedicationUserSidebarActive
      "
      :medicalHistory="medicalHistory"
      :optionsMedications="medicationsOptions"
      @createMedication="createMedication"
      @createMedicationUser="createMedicationUser"
    />
    <MedicationEdit
      v-if="isEditMedicationSidebarActive"
      :is-edit-medication-sidebar-active.sync="isEditMedicationSidebarActive"
      :optionsMedications="medicationsOptions"
      :itemEdit="itemEdit"
      @editMedicationSuccess="editMedicationSuccess"
    />
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">Medical History</h5>
      <small class="text-muted">Enter the patient's medical history.</small>
    </b-col>
    <b-col md="6">
      <validation-provider #default="{ errors }" name="PMHx">
        <b-form-group
          label-for="pmhx"
          label="PMHx"
          :state="errors.length > 0 ? false : null"
        >
          <v-select
            id="pmhx"
            v-model="medicalHistory.ailments"
            @search="searchAilment"
            :options="ailmentOptions.data"
            multiple
            label="name"
          >
            <template #no-options="{ search, searching }">
              <NewItemSelect
                v-if="searching"
                :search="search"
                @onSaveVisitReason="onSaveAilment(search)"
              />
            </template>
          </v-select>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
    <b-col md="6">
      <validation-provider #default="{ errors }" name="Allergies">
        <b-form-group
          label-for="allergies"
          label="Allergies"
          :state="errors.length > 0 ? false : null"
        >
          <v-select
            id="allergies"
            v-model="medicalHistory.allergies"
            @search="searchAllergies"
            :options="allergiesOptions.data"
            multiple
            label="name"
          >
            <template #no-options="{ search, searching }">
              <NewItemSelect
                v-if="searching"
                :search="search"
                @onSaveVisitReason="onSaveAllergy(search)"
              />
            </template>
          </v-select>
          <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
            {{ errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
    <b-col md="12">
      <b-card>
        <div class="d-flex align-items-center justify-content-end mb-2">
          <b-button
            :disabled="loading"
            variant="primary"
            @click="isAddNewMedicationUserSidebarActive = true"
          >
            <span v-if="!loading" class="text-nowrap"
              >{{ $t("Add") }} Medication User</span
            >
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </div>
        <b-table
          :items="medicalHistoryMutable"
          :fields="fields"
          striped
          bordered
          responsive
          hover
          class="position-relative text-center"
          show-empty
          empty-text="No matching records found"
        >
          <template #cell(note)="data">
            <div class="overflow-auto" style="height: 100px">
              {{ data.item.note }}
            </div>
          </template>
          <template #cell(status_medications_id)="data">
            <b-button-group size="sm" role="group" id="toolBtns">
              <b-button
                v-for="state in stateMedications"
                :key="state.id"
                class="btn btn-icon"
                type="button"
                variant="outline-secondary"
                v-b-tooltip.hover.top="state.name"
                :class="{
                  active: data.item.status_medications_id === state.id,
                }"
                :style="{
                  background:
                    data.item.status_medications_id === state.id
                      ? state.color
                      : '',
                  color:
                    data.item.status_medications_id === state.id ? 'white' : '',
                }"
              >
                {{ state.prefix }}
              </b-button>
            </b-button-group>
          </template>
          <template #cell(actions)="data">
            <div>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-primary"
                class="btn-icon"
                v-b-tooltip.hover.top="'Update'"
                @click="editMedication(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-danger"
                class="btn-icon"
                v-b-tooltip.hover.top="'Delete'"
                @click="deleteMedication(data.item)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BCard,
  BTable,
  BFormCheckbox,
  BFormInvalidFeedback,
  BButtonGroup,
  BButton,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

import axiosAI from "@/services/admin/consults/ailments";
import axiosAL from "@/services/admin/consults/allergies";
import axiosM from "@/services/admin/consults/medications";
import axiosMP from "@/services/office-visit/medications";

import NewItemSelect from "@/components/NewItemSelect";
import MedicationAddUser from "@/views/office-visit/office-visit-review/medication/MedicationAdd";
import MedicationEdit from "@/views/office-visit/office-visit-review/medication/MedicationEdit";
import { ref } from "vue-demi";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BCard,
    BTable,
    BFormCheckbox,
    BTable,
    BFormInvalidFeedback,
    BButtonGroup,
    BButton,
    vSelect,

    NewItemSelect,
    MedicationAddUser,
    MedicationEdit,
  },
  props: {
    medicalHistory: {
      type: Object,
      required: true,
    },
    allergiesOptions: {
      type: Object,
      required: true,
    },
    ailmentOptions: {
      type: Object,
      required: true,
    },
    medicationsOptions: {
      type: Object,
      required: true,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      medicalHistoryMutable: this.medicalHistory.medicationInformation,
      medicationsPatient: [],
      stateMedications: [
        {
          id: 1,
          name: "Taking",
          prefix: "T",
          color: "#d1192b",
        },
        {
          id: 2,
          name: "Not Taking",
          prefix: "N",
          color: "#120b75",
        },
        {
          id: 3,
          name: "Discontinued",
          prefix: "D",
          color: "#12a312",
        },
        {
          id: 4,
          name: "Unknown Status",
          prefix: "U",
          color: "#ffd900",
        },
      ],
      activeBtn: "",
      fields: [
        // A column that needs custom formatting
        { key: "medications.name", label: "Name", sortable: true },
        { key: "start_date", label: "Start Date", sortable: true },
        { key: "start_stop", label: "Stop Date", sortable: true },
        { key: "note", label: "note", sortable: true, class: "th-class-note" },
        // A virtual column made up from two fields
        { key: "status_medications_id", label: "Status", sortable: true },
        { key: "actions", label: "Actions" },
      ],
      loading: false,
      setTimeoutBuscador: "",
      isAddNewMedicationUserSidebarActive: false,
      isEditMedicationSidebarActive: false,
      itemEdit: null,
    };
  },
  methods: {
    getAilments() {
      axiosAI.ailmentList(100).then(({ data }) => {
        this.ailmentOptions.data = data;
      });
    },
    getAllergies() {
      axiosAL.allergyList(10).then(({ data }) => {
        this.allergiesOptions.data = data;
      });
    },
    getMedications() {
      axiosM.medicationList(10).then(({ data }) => {
        this.medicationsOptions.data = data;
      });
    },
    searchAilment(ailment, loading) {
      if (ailment.length) {
        loading(true);
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: ailment,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosAI.ailmentFilter(10, datos).then(({ data }) => {
            loading(false);
            this.ailmentOptions.data = data;
          });
        }, 350);
      }
    },
    searchAllergies(allergy, loading) {
      if (allergy.length) {
        loading(true);
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: allergy,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosAL.allergyFilter(10, datos).then(({ data }) => {
            loading(false);
            this.allergiesOptions.data = data;
          });
        }, 350);
      }
    },
    onSaveAilment(name) {
      const data = {
        name,
      };
      axiosAI.ailmentCreate(data).then(({ registro }) => {
        this.medicalHistory.ailments.push(registro);
        this.ailmentOptions.data = [...this.ailmentOptions.data, registro];
      });
    },
    onSaveAllergy(name) {
      const data = {
        name,
      };
      axiosAL.allergyCreate(data).then(({ registro }) => {
        this.medicalHistory.allergies.push(registro);
        this.allergiesOptions.data = [...this.allergiesOptions.data, registro];
      });
    },
    editMedication(item) {
      this.isEditMedicationSidebarActive = true;
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)));
    },
    editMedicationSuccess() {
      this.getInformationMedications();
      this.isEditMedicationSidebarActive = false;
    },
    deleteMedication(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosMP
            .paientMedicationDelete(item.historyble_id)
            .then((response) => {
              this.getInformationMedications();
            });
        }
      });
    },
    getInformationMedications() {
      axiosMP
        .paientMedicationInformation(this.medicalHistory.userId)
        .then((res) => {
          const {
            histories: { medication_informations },
          } = res[0];
          this.medicalHistory.medicationInformation = medication_informations;
          this.medicalHistoryMutable = medication_informations;
        });
    },
    createMedicationUser() {
      this.getInformationMedications();
      this.isAddNewMedicationUserSidebarActive = false;
    },
    createMedication() {
      this.getMedications();
    },
  },
};
</script>

<style lang="scss">
.th-class-note {
  width: 25%;
  padding: 0.9rem 0.1rem !important;
}
</style>
