import { userAxios } from '@/services'

const diagnosesList = async (perPage) => {
    try {
        return await userAxios.get(`diagnoses/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosesCreate = async (data) => {
    try {
        return await userAxios.post('diagnoses', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosesUpdate = async (id, data) => {
    try {
        return await userAxios.put(`diagnoses/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosesDelete = async (id) => {
    try {
        return await userAxios.delete(`diagnoses/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosesPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`diagnoses/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const diagnosesFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`diagnoses/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
const diagnosesFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`diagnoses/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    diagnosesList,
    diagnosesCreate,
    diagnosesUpdate,
    diagnosesDelete,
    diagnosesPagination,
    diagnosesFilter,
    diagnosesFilterPagination
}