<template>
  <b-row class="mt-2">
    <ReviewSystemAdd
      v-if="isAddNewReviewSystemSidebarActive"
      :is-add-new-review-system-sidebar-active.sync="
        isAddNewReviewSystemSidebarActive
      "
      @createReviewSystem="createReviewSystem"
    />
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">Review System</h5>
      <small class="text-muted">Enter the review system</small>
    </b-col>
    <b-col
      md="6"
      xl="3"
      class="my-1"
      v-for="item in reviewSystemLength.data"
      :key="item.id"
    >
      <b-form-group :label="item.name" :label-for="item.name">
        <validation-provider #default="{ errors }" :name="item.name">
          <v-select
            :id="item.name"
            v-model="reviewSystemSelected[item.name]"
            :options="item.review_of_system"
            multiple
            :reduce="(value) => value.id"
            label="name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="3" class="d-flex flex-column">
      <label for="">{{ $t("Add") }} Review System</label>
      <b-button
        variant="success"
        class="btn-icon"
        @click="isAddNewReviewSystemSidebarActive = true"
      >
        <feather-icon icon="PlusIcon" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BRow, BCol, BFormGroup, BButton } from "bootstrap-vue";
import vSelect from "vue-select";

import ReviewSystemAdd from "@/views/admin/type-consult/review-system/ReviewSystemAdd";

import axiosRSC from "@/services/admin/consults/reviewSystemCategories";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    vSelect,
    ReviewSystemAdd,
  },
  props: {
    reviewSystemSelected: {
      type: Object,
      required: true,
    },
    reviewSystemLength: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      isAddNewReviewSystemSidebarActive: false,
    };
  },
  methods: {
    getReviewSystemCategory() {
      axiosRSC.reviewSystemCategoryList(20).then(({ data }) => {
        this.reviewSystemLength.data = data;
      });
    },
    createReviewSystem({ registro }) {
      const category = this.reviewSystemLength.data.filter(
        (item) => item.id == registro.category_id
      )[0];
      this.isAddNewReviewSystemSidebarActive = false;
      if (this.reviewSystemSelected[category.name]) {
        this.reviewSystemSelected[category.name].push(registro.id);
      } else {
        this.reviewSystemSelected[category.name] = [registro.id];
      }
      //this.reviewSystemSelected[category.name].push(registro);
      this.getReviewSystemCategory();
    },
  },
};
</script>

<style lang="scss">
</style>