import { userAxios } from '../index'

const packageslList = async (perPage) => {
    try {
        return await userAxios.get(`procedure-packages/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageCreate = async (data) => {
    try {
        return await userAxios.post('procedure-packages', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageUpdate = async (id, data) => {
    try {
        return await userAxios.put(`procedure-packages/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageView = async (id) => {
    try {
        return await userAxios.get(`procedure-packages/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`procedure-packages/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packagePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`procedure-packages/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`procedure-packages/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const categoryClinicalPackages = async (data ) => {
	try {
		return await userAxios.get(`clinical-package-categories/`).then(res => res.data)
} catch (error) {
		console.log(error);
}
}

export default {
    packageslList,
    packageCreate,
    packageUpdate,
    packageView,
    packageFilter,
    packagePagination,
    packageFilterPagination,
		categoryClinicalPackages
}