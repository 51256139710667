import { userAxios } from '../index'

const paientMedicationInformation = async (id) => {
    try {
        return await userAxios.get(`medication_information/user/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const paientMedicationCreate= async (data) => {
    try {
        return await userAxios.post(`medication_information`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const paientMedicationUpdate = async (id, data) => {
    try {
        return await userAxios.put(`medication_information/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const paientMedicationDelete = async (id) => {
    try {
        return await userAxios.delete(`medication_information/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const paientMedicationFilter= async (id, data) => {
    try {
        return await userAxios.post(`patients/medication/filter/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}


export default {
    paientMedicationInformation,
    paientMedicationCreate,
    paientMedicationUpdate,
    paientMedicationDelete,
    paientMedicationFilter
}