<template>
  <div>
    <form-wizard
      :color="colorForm"
      :title="null"
      :subtitle="null"
      shape="square"
      layout="vertical"
      class="mb-3 primary wizard-vertical"
      transition="bounce"
      ref="wizard"
    >
      <div class="d-flex">
        <GoBack class="mr-2" />
        <div class="d-flex" v-if="pickMedication">
          <div class="d-flex align-items-center">
            <span class="">
              <feather-icon size="24" icon="ThermometerIcon" />
            </span>
            <div class="d-flex flex-column">
              <p class="text-dark-75 font-weight-bolder font-size-sm mb-0">
                {{ pickMedication }}
              </p>
              <small
                href="#"
                class="text-primary text-muted font-weight-bolder mb-0"
                >Pick Up Medication</small
              >
            </div>
          </div>
        </div>
      </div>

      <!-- patient details tab -->
      <wizard-step
        slot-scope="props"
        slot="step"
        :tab="props.tab"
        :index="props.index"
        :transition="props.transition"
        @click.native="navigate(props.navigateToTab, props.index)"
      >
        <div slot="title">
          <div class="d-flex flex-column">
            <span class="stepTitle align-self-start">
              <p class="p-0 my-0" :class="getValidResult(props)">
                {{ props.tab.title }}
                <feather-icon
                  v-if="props.isPendient"
                  icon="DeleteIcon"
                  size="16"
                />
              </p>
            </span>
            <small class="align-self-start text-primary">{{
              getSubTitle(props.index)
            }}</small>
          </div>
        </div>
      </wizard-step>

      <tab-content
        :title="$t('FormWizard.PatientDetails')"
        :before-change="validationPatients"
      >
        <validation-observer ref="accountRules" tag="form">
          <PatientDetails :detail="detail" :facilityOptions="facilityOptions" />
        </validation-observer>
      </tab-content>

      <!-- laboratories -->
      <tab-content :title="$t('FormWizard.Laboratory')" v-if="is_only_calculate_self_pay_visit == true">
        <validation-observer ref="" tag="form">
          <LaboratoryProcedure
            v-if="medicalHistoryConditional"
            :laboratorySelected="laboratorySelected"
            :laboratoryLength="laboratoryLength"
            :procedurePacks="procedurePacks"
            :visitType="visitTypeAppoinment"
            :isInsuranceCondition="isInsuranceCondition"
            :insuranceDetails="{
              deductibleProgress:
                insuranceDetails.deductibleValue - totalDeductibleUsed,
              coInsuragePercentage: insuranceDetails.coInsuragePercentage,
            }"
            :totalPriceLaboratory="totalPriceLaboratory"
            :totalPrice="totalPriceProcedure"
            :totalFinalWithInsurance="totalFinalWithInsurance"
            :promotionOfficeVisit="promotionOfficeVisit"
            @calculateInsurance="calculatedPrices"
          />
        </validation-observer>
      </tab-content>

      <!-- Interventions -->
      <tab-content :title="'IH ' + $t('FormWizard.Interventions')">
        <validation-observer ref="" tag="form">
          <InterventionsProcedure
            v-if="medicalHistoryConditional"
            :interventionsProcedure="interventionsProcedure"
            :testOptions="testOptions"
            :procedureOptions="procedureOptions"
            :visitType="visitTypeAppoinment"
            :isInsuranceCondition="isInsuranceCondition"
            :insuranceDetails="{
              deductibleValue: insuranceDetails.deductibleValue,
              deductibleProgress:
                insuranceDetails.deductibleValue - totalDeductibleUsed,
              coInsuragePercentage: insuranceDetails.coInsuragePercentage,
            }"
            :totalPrice="totalPriceProcedure"
            :totalPriceInterventions="totalPriceInterventions"
            :totalFinalWithInsurance="totalFinalWithInsurance"
            :isPackageOfficeVisit="isPackageOfficeVisit"
            :promotionOfficeVisit="promotionOfficeVisit"
            @calculateInsurance="calculatedPrices"
          />
        </validation-observer>
      </tab-content>

      <!-- Treatment -->
      <tab-content :title="'IH ' + $t('FormWizard.Treatment')">
        <validation-observer ref="" tag="form">
          <TreatmentProcedure
            v-if="treatmentProcedure.length > 0"
            :treatmentProcedure="treatmentProcedure"
            :treatmentOptions="treatmentOptions"
            :pendingTreatments="pendingTreatments"
            :totalPriceTreatment="totalPriceTreatments"
            :insuranceDetails="{
              totalDeductibleUsed: totalDeductibleUsed,
              deductibleValue: insuranceDetails.deductibleValue,
              deductibleProgress:
                insuranceDetails.deductibleValue - totalDeductibleUsed,
              coInsuragePercentage: insuranceDetails.coInsuragePercentage,
            }"
            :totalPrice="totalPriceProcedure"
            :totalFinalWithInsurance="totalFinalWithInsurance"
            :detail="detail"
            :isPackageOfficeVisit="isPackageOfficeVisit"
            :countUpdatePackage="countUpdatePackage"
            :promotionOfficeVisit="promotionOfficeVisit"
            @calculateInsurance="calculatedPrices"
          />
        </validation-observer>
      </tab-content>

      <!-- imaging -->
      <tab-content :title="$t('FormWizard.DiagnosticImaging')" v-if="is_only_calculate_self_pay_visit == true">
        <validation-observer ref="" tag="form">
          <ImagingProcedure
            :diagnosticSelected="diagnosticSelected"
            :diagnosticLength="diagnosticLength"
            :totalPriceDiagnosticImaging="totalPriceDiagnosticImaging"
            :totalPrice="totalPriceProcedure"
            :totalFinalWithInsurance="totalFinalWithInsurance"
            :insuranceDetails="{
              deductibleProgress:
                insuranceDetails.deductibleValue - totalDeductibleUsed,
              coInsuragePercentage: insuranceDetails.coInsuragePercentage,
            }"
            :isPackageOfficeVisit="isPackageOfficeVisit"
            :promotionOfficeVisit="promotionOfficeVisit"
            @calculateInsurance="calculatedPrices"
          />
        </validation-observer>
      </tab-content>

      <!-- costs -->
      <tab-content
        :title="$t('FormWizard.ChargesCosts')"
        :before-change="validationFormCost"
      >
        <validation-observer ref="costRules" tag="form">
          <CostProcedure
            :totalPriceDiagnosticImaging="totalPriceDiagnosticImaging"
            :totalPriceLaboratory="totalPriceLaboratory"
            :totalPriceInterventions="totalPriceInterventions"
            :totalPriceTreatment="totalPriceTreatments"
            :totalInHouse="totalDeductibleUsed + totalDiscounted"
            :totalFinalWithInsurance="totalFinalWithInsurance"
            :getCostProcedure="costProcedure"
            :detailsPatient="detail"
            :consultation="consultationPrice"
            :descountInsurance="totalDiscounted"
            :visitRates="visitRates"
            :isPromotion="isPromotion"
            :isCopayment="isCopayment"
            :isInsurance="isInsuranceCondition"
            :isInHouse="isInHouseCondition"
            :isVisitAdjustment="isVisitAdjustment"
            :isPackageOfficeVisit="isPackageOfficeVisit"
            :totalPricePackage="totalPricePackage"
            :isInsuranceFacility="true"
            :campaignCupon="campaignCupon"
            @totalAdjustment="totalAdjustment"
            @totalPrice="totalPrice"
            @calculateInsurance="calculatedPrices"
          >
            <div v-if="costProcedure.signature != null">
              <img :src="costProcedure.signature" alt="" />
            </div>
            <div v-else>
              <b-form-group label="Signature" label-for="signature">
                <div class="d-flex">
                  <VueSignaturePad
                    id="signature"
                    width="100%"
                    height="20vh"
                    ref="signaturePad"
                    class="border border-primary rounded"
                    :options="{
                      onBegin: () => {
                        $refs.signaturePad.resizeCanvas();
                      },
                    }"
                  />
                  <div class="align-self-center">
                    <b-button
                      class="btn-icon align-self-center mx-1 cursor-pointer"
                      variant="danger"
                      @click="clearSignature"
                    >
                      <feather-icon icon="TrashIcon" size="16" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </div>
          </CostProcedure>
        </validation-observer>
      </tab-content>

      <!-- Footer Template -->
      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0 && !props.isLastStep"
            @click.native="props.prevTab()"
            :style="props.fillButtonStyle"
            >{{ $t("Form.Previous") }}</wizard-button
          >
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="showSubmit(props.activeTabIndex)"
            @click.native="omaSubmitted"
            class="wizard-footer-right finish-button d-flex"
            :disabled="sendingForm"
            :style="props.fillButtonStyle"
          >
            <span v-if="!sendingForm">{{ $t("Form.Submit") }}</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </wizard-button>
          <wizard-button
            v-else
            @click.native="props.nextTab()"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            >{{ $t("Form.Next") }}</wizard-button
          >
        </div>
      </template>
    </form-wizard>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  FormWizard,
  TabContent,
  WizardStep,
  WizardButton,
} from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastNotification from "@/components/ToastNotification";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import "cleave.js/dist/addons/cleave-phone.us";

import axiosOV from "@/services/office-visit";
import axiosEC from "@/services/external-settings/images";

import PatientDetails from "../procedure/PatientDetails.vue";
import MedicalHistory from "../procedure/MedicalHistory.vue";
import ReviewSystem from "../procedure/ReviewSystem.vue";
import VitalSign from "../procedure/VitalSign.vue";
import AssessmentProcedure from "../procedure/AssessmentProcedure.vue";
import LaboratoryProcedure from "../procedure/LaboratoryProcedure.vue";
import InterventionsProcedure from "../procedure/InterventionsProcedure.vue";
import TreatmentProcedure from "../procedure/TreatmentProcedure";
import ImagingProcedure from "../procedure/ImagingProcedure.vue";
import CostProcedure from "../procedure/CostProcedure.vue";
import PackageProcedure from "../procedure/PackageProcedure.vue";

import GoBack from "@/components/ButtonBack.vue";
import SpinnerLoading from "@/components/SpinnerLoading";
import { notificationCheck } from "@/mixins/NotificationSetup";
import { convertirBase64AFile } from "@/mixins/Base64ToFile";

import { $themeColors } from "@themeConfig";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    WizardStep,
    WizardButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    vSelect,
    Cleave,
    BFormInvalidFeedback,
    ToastNotification,

    PatientDetails,
    MedicalHistory,
    ReviewSystem,
    VitalSign,
    AssessmentProcedure,
    LaboratoryProcedure,
    ImagingProcedure,
    TreatmentProcedure,
    InterventionsProcedure,
    CostProcedure,
    PackageProcedure,

    GoBack,
    SpinnerLoading,
  },
  data() {
    return {
      colorForm: $themeColors.primary,
      sendingForm: false,
      detail: {
        name: "",
        lastName: "",
        phone: "",
        dateBirth: null,
        facility: null,
        room: null,
      },
      medicalHistory: {
        ailments: [],
        allergies: [],
        medicationInformation: [],
        current_medications: false,
        userId: null,
        historyId: null,
      },
      vitalSign: [],
      ailmentOptions: {
        data: null,
      },
      allergiesOptions: {
        data: null,
      },
      medicationsOptions: {
        data: null,
      },
      reviewSystemLength: {
        data: null,
      },
      diagnosesOptions: {
        data: null,
      },
      testOptions: {
        data: null,
      },
      procedureOptions: {
        data: null,
      },
      treatmentProcedure: [],
      assessmentProcedure: {
        examination: null,
        describeAbnormal: null,
        describeDiagnoses: null,
        diagnosis: [],
      },
      reviewSystemLength: {
        data: null,
      },
      laboratoryLength: {
        data: null,
      },
      diagnosticLength: {
        data: null,
      },
      reviewSystemSelected: {},
      laboratorySelected: {},
      diagnosticSelected: {},
      interventionsProcedure: {
        tests: [],
        procedures: [],
      },
      procedurePacks: {
        laboratoriesPack: [],
        checkCategoryLaboratory: {},
      },
      facilityOptions: [],
      signVitalsOptions: [],
      treatmentOptions: [],
      loading: false,
      visitTypeAppoinment: null,
      isVisitAdjustment: false,
      conditionalSignature: false,
      totalPriceProcedure: 0,
      totalPricePackage: 0,
      costProcedure: {
        signature: null,
        adjusment: 0,
        authorization: false,
      },
      consultationPrice: {
        price: 0,
      },
      visitRates: [],
      medicalHistoryConditional: false,
      isPromotion: false,
      isInsuranceCondition: false,
      isInHouseCondition: true,
      isCopayment: false,
      idOfficeVisit: null,
      insuranceDetails: {
        coInsuragePercentage: 0,
        deductibleValue: 0,
        deductibleProgress: 0,
      },
      isInsuranceOnly: false,
      arrayInsurance: [],
      totalFinalWithInsurance: 0,
      tempConsultationPrice: 0,

      isPackageOfficeVisit: null,

      promotionOfficeVisit: {
        promotionProcedure: [],
        promotionTest: [],
        laboratoryPromotion: [],
        promotionImaging: [],
        promotionTreatment: [],
      },
      treatmentAll: null,
      pendingTreatments: [],
      isPackageOfficeVisitSelected: null,
      pickMedication: null,
      countUpdatePackage: 0,
      categorySelectedPackage: null,
      is_only_calculate_self_pay_visit: true,
      campaignCupon: {}
    };
  },
  mounted() {
    this.getDetails();
    this.$refs.wizard.activateAll();
  },
  computed: {
    totalPriceLaboratory() {
      let total = 0;
      if (this.isInsuranceOnly) {
        return total;
      }
      const laboratorySelected = this.laboratorySelected;
      for (const key in laboratorySelected) {
        laboratorySelected[key].forEach(
          (item) => (total += parseFloat(item.price))
        );
      }
      const laboratoryPackSelected = this.procedurePacks.laboratoriesPack;
      for (const key of laboratoryPackSelected) {
        total += parseFloat(key.price);
      }
      return total;
    },
    totalPriceInterventions() {
      // Calcula el precio total de las intervenciones sin aplicar el deducible y la coaseguro
      let total = 0;
      if (this.isInsuranceOnly) {
        return total;
      }
      const { procedures, tests } = this.interventionsProcedure;
      const totalInterventions = procedures.concat(tests);
      for (const key of totalInterventions) {
        total += parseFloat(key.price);
      }
      return total;
    },
    totalPriceTreatments() {
      let total = 0;
      if (this.isInsuranceOnly) {
        return total;
      }

      for (const key of this.treatmentProcedure) {
        const promotionCant = key.options.filter((item) => item.promotion);

        const promotionAvailivable = promotionCant.length;

        const pendingT = this.pendingTreatments.find(pending => pending.id === key.id);

        const totalTreatment = pendingT ? ((key.cant - pendingT.pending) - promotionAvailivable) : (key.cant - promotionAvailivable);
        if (totalTreatment < 0) {
          totalTreatment = 0;
        }

        total += parseFloat(key.price) * totalTreatment;
      }

      return total;
    },
    totalPriceDiagnosticImaging() {
      if (this.isInsuranceOnly) {
        return 0;
      }
      const diagnosticSelected = this.diagnosticSelected;
      const total = Object.values(diagnosticSelected)
        .flat()
        .reduce((acc, item) => acc + parseFloat(item.price), 0);
      return total;
    },
    totalInHouse() {
      let priceSelected = 0;
      if (this.isInsurance) {
        priceSelected = this.consultationPrice.price
          ? this.consultationPrice.price.value
          : 0;
      } else {
        priceSelected = this.consultationPrice.price;
      }
      return (
        this.totalPriceInterventions + this.totalPriceTreatments + priceSelected
      );
    },
    totalDeductibleUsed() {
      const { deductibleValue } = this.insuranceDetails;
      const sumAll =
        this.totalInHouse +
        this.totalPriceLaboratory +
        this.totalPriceDiagnosticImaging;
      if (sumAll > deductibleValue) {
        return deductibleValue;
      } else {
        return sumAll;
      }
    },
    totalDiscounted() {
      //hacer descuento de coaseguro a los totalInHouse que hayan pasado el deducible, no a los que ya estan en el deducible
      //los 5 procedimientos (laboratorios, intervenciones, tratamientos, imagenes y consulta) disminuyen el deducible
      const { coInsuragePercentage } = this.insuranceDetails;
      const deductibleUsed = this.totalDeductibleUsed;

      let totalDiscounted = 0;
      const sumAllInsurance = this.totalInHouse;
      const sumAllProceduresOut =
        this.totalPriceLaboratory + this.totalPriceDiagnosticImaging;

      const sumAll = sumAllInsurance + sumAllProceduresOut;

      const remaining = sumAll - deductibleUsed;
      const remainingOut = sumAllProceduresOut - deductibleUsed;
      //hacer descuento de coaseguro solo si se descontó el deducible y hay precio en sumAllInsurance
      if (
        deductibleUsed >= 0 &&
        sumAll > deductibleUsed &&
        sumAllInsurance > 0
      ) {
        if (remaining > 0 && remainingOut < 0) {
          totalDiscounted = remaining * (coInsuragePercentage / 100);
        } else {
          //sacar el descuento de la suma de los procedimientos de in house
          totalDiscounted = sumAllInsurance * (coInsuragePercentage / 100);
        }
      }

      if (remaining === 0) {
        return remaining - totalDiscounted;
      }

      return totalDiscounted;
    },
  },
  watch: {
  },
  methods: {
    calculatedPrices(data, method = "create") {
      /* add or delete elements in arrayInsurance */
      if (method == "delete") {
        /* delete items */
        this.arrayInsurance = this.arrayInsurance.filter((element) => {
          return !(element.itemId == data.itemId && element.id == data.id);
        });

        /* delete cost procedure */
        if (data.costProcedure) {
          const index = this.arrayInsurance
            .map((item) => item.itemId)
            .indexOf(data.itemId);
          this.arrayInsurance.splice(index, 1);
        }
      } else {
        const findItem = this.arrayInsurance.find(
          (element) => element.itemId === data.itemId && element.id === data.id
        );

        if (findItem) {
          this.arrayInsurance = this.arrayInsurance.map((element) => {
            if (element.itemId === data.itemId && element.id === data.id) {
              (element.cant = data.cant), (element.total = data.total);
            }
            return element;
          });
        } else if (data.costProcedure) {
          const index = this.arrayInsurance
            .map((item) => item.itemId)
            .indexOf(data.itemId);
          if (index !== -1) {
            this.arrayInsurance.splice(index, 1, data);
          } else {
            this.arrayInsurance.push(data);
          }
        } else {
          this.arrayInsurance.push(data);
        }
      }

      /* calculate deductible */
      let calculateDeductible = 0;
      let calculateDiscount = 0;
      const { deductibleValue, coInsuragePercentage } = this.insuranceDetails;

      if (this.isInsuranceOnly) {
        this.totalPriceProcedure = 0;
        return;
      }

      if(this.is_only_calculate_self_pay_visit) return;

      /* no in house */
      // if(coInsuragePercentage && !deductibleValue){
      //   this.isInHouseCondition = false;
      //   /* delete cost consult */
      //   // this.arrayInsurance = this.arrayInsurance.filter((data) => data.type != 1 && data.itemId != 10);
      //   // this.consultationPrice.price = null;
      // }
      // const arrInHouse = this.arrayInsurance.filter((data) => data.type == 1 && data.itemId != 10);
      // if(arrInHouse.length <= 0 && coInsuragePercentage){
      //   this.isInHouseCondition = false;
      //   /* delete cost consult */
      //   this.arrayInsurance = this.arrayInsurance.filter((data) => data.type != 1 && data.itemId != 10);
      //   this.consultationPrice.price = null;
      // }else{
      //   this.isInHouseCondition = true;
      // }

      /* calculate deductible and discount */
      for (const key of this.arrayInsurance) {
        if (calculateDeductible <= deductibleValue) {
          const tmpPrice = deductibleValue - calculateDeductible;
          if (key.total > tmpPrice) {
            calculateDeductible += tmpPrice;
            /* is in house */
            if (key.type == 1) {
              calculateDiscount += key.total - tmpPrice;
            }
          } else {
            calculateDeductible += key.total;
          }
        } else if (key.type == 1) {
          calculateDiscount += key.total;
        }
      }

      const totalDiscount = (calculateDiscount * coInsuragePercentage) / 100;
      this.totalFinalWithInsurance = calculateDeductible + totalDiscount;
    },
    /* methods */
    getSubTitle(id) {
      if (id === 0) return "Office Visit Info";
      if (id === 1) return "Subjective";
      if (id === 2) return "Treatment";
      // if (id === 3) return "Objective";
      // if (id === 4) return "Objective/Assessment";
      // if (id === 5 || id === 6 || id === 7 || id === 8) return "Treatment";
      if (id === 3 || id === 5) return "Billing";
      return "";
    },
    navigate(navigateMethod, index) {
      let wizard = this.$refs.wizard;
      if (index === wizard.activeTabIndex + 1) {
        wizard.nextTab();
      } else {
        navigateMethod(index);
      }
    },
    async getDetails() {
      this.idOfficeVisit = this.$route.params.id;
      const { check, message } = await this.omaProcedure(this.idOfficeVisit);
      if (check) {
        this.loading = true;
        axiosOV
          .procedureOmaInsurance(this.idOfficeVisit)
          .then(({ all, office_visit, paginates, insurance }) => {
            const {
              // type_vital_sign,
              review_of_system: CategoryReviewSystem,
              category_lab: CategoryLaboratory,
              treatments,
              sonograms: CategoryDiagnosticImaging,
              visit_rates,
            } = all;

            this.treatmentAll = treatments;

            // this.signVitalsOptions = type_vital_sign;
            // this.vitalSign = this.signVitalsOptions.map((item) => {
            //   return {
            //     id: item.id,
            //     value: null,
            //   };
            // });

            // this.facilityOptions.push(...rooms);

            const {
              appointments: {
                patients,
                facilities,
                ajustment,
                consult_prices,
                cupons_id,
                visit_rates: visitRates,
                type_visits_id,
                appointment_dataset,
                clinical_package_id,
                clinical_package,
                pick_up_medication,
                is_only_calculate_self_pay_visit,
                is_only_calculate_insurance_visit,
              },
              room,
              review_of_system,
              type_vital_sign: VitalSignUser,
              examination,
              describe_abnormal,
              describe_diagnoses,
              diagnosis,
              laboratories,
              laboratories_pack,
              diagnostic_imagings,
              tests: testUser,
              procedures: procedureUser,
              treatments: treatmentUser,
              signature,
              authorization,
              pending_treatments,
            } = office_visit;

            this.is_only_calculate_self_pay_visit = is_only_calculate_self_pay_visit;

            this.pendingTreatments = pending_treatments ?? [];

            if (pick_up_medication && pick_up_medication.name) {
              this.pickMedication = pick_up_medication.name;
            }

            this.isPackageOfficeVisit = clinical_package_id;
            this.isPackageOfficeVisitSelected = clinical_package_id;
            this.totalPricePackage =
              clinical_package && parseFloat(clinical_package.price);
            this.categorySelectedPackage =  clinical_package && parseFloat(
              clinical_package.clinical_package_category_id
            );

            const { insurancePercentage, status, deductible, statusCopay } =
              this.isInsurance(insurance);

            if (insurancePercentage && status && !deductible) {
              this.isInHouseCondition = false;
            }

            /* data set appoiment */
            if (office_visit.appointments.appointment_dataset != null) {
              const dataSet = JSON.parse(
                office_visit.appointments.appointment_dataset.appointment
              ) ?? [];
              dataSet.forEach((item) => {
                this.calculatedPrices(item);
              });
            }
            

            this.visitTypeAppoinment = type_visits_id;
            this.isVisitAdjustment =
              type_visits_id === 10 ||
              type_visits_id === 11 ||
              type_visits_id === 12
                ? true
                : false;

            this.isPromotion =
              cupons_id || clinical_package_id === null ? false : true;

            this.detail = {
              name: patients.name,
              lastName: patients.lastname,
              phone: patients.phone,
              dateBirth: patients.date_birth,
              facility: facilities.name,
              room,
            };

            const { histories } = patients;
            this.medicalHistory = {
              ailments: histories.ailment,
              allergies: histories.allergies,
              medicationInformation: histories.medication_informations,
              current_medications: histories.current_medications,
              userId: histories.users_id,
              historyId: histories.id,
            };

            // VitalSignUser.forEach((elem, i) => {
            //   const index = this.vitalSign.findIndex(
            //     (item) => item.id === elem.id
            //   );
            //   if (index !== -1) {
            //     this.vitalSign[index].value = elem.pivot.value;
            //   }
            // });

            const {
              ailments,
              allergies,
              medication,
              diagnoses,
              tests,
              procedure,
            } = paginates;

            // this.ailmentOptions.data = ailments.data;
            // this.allergiesOptions.data = allergies.data;
            // this.medicationsOptions.data = medication.data;
            // this.diagnosesOptions.data = diagnoses.data;
            this.testOptions.data = tests.data.map((item) => {
              return {
                ...item,
                price: is_only_calculate_insurance_visit && item.insurance_price ? parseInt(item.insurance_price) : parseInt(item.price)
              }
            });

            this.procedureOptions.data = procedure.data.map((item) => {
              return {
                ...item,
                price: is_only_calculate_insurance_visit && item.insurance_price ? parseInt(item.insurance_price) : parseInt(item.price)
              }
            });

            let laboratoriesObject = {};

            // this.reviewSystemSelected = this.createInicialCategories(
            //   review_of_system,
            //   "category"
            // );

            this.reviewSystemLength.data = CategoryReviewSystem;

            // const diagnosisFinal = diagnosis.map((item) => {
            //   return {
            //     id: item.assessmentable_id,
            //     ...item,
            //   };
            // });
            // this.assessmentProcedure = {
            //   examination,
            //   describeAbnormal: describe_abnormal,
            //   describeDiagnoses: describe_diagnoses,
            //   diagnoses: diagnosisFinal,
            // };

            let laboratoriesUser = laboratories;

            // verificar si hay promocion en los laboratorios
            if (
              this.isPromotion &&
              laboratories.some((item) => item.pivot.promotion)
            ) {
              const { promitions, array } = this.clearPromotions(
                laboratories,
                CategoryLaboratory,
                "laboratories"
              );
              laboratoriesUser = array;
              this.promotionOfficeVisit.laboratoryPromotion = promitions;
            }

            laboratoriesUser.forEach((x) => {
              if (!laboratoriesObject.hasOwnProperty(x.categories[0].name)) {
                laboratoriesObject[x.categories[0].name] = [];
              }
              laboratoriesObject[x.categories[0].name].push(x);
            });
            for (const laboratory of laboratories_pack) {
              const id = laboratory.office_visitable_id;
              this.procedurePacks.checkCategoryLaboratory[id] = true;
              this.procedurePacks.laboratoriesPack.push({ id, ...laboratory });
            }

            this.laboratorySelected = laboratoriesObject;
            const catLaboratories = CategoryLaboratory.map(
              ({ laboratories, ...res }) => {
                const laboratoriesM = laboratories.filter(
                  (laboratory) => laboratory.price != null
                );
                return {
                  laboratoriesM,
                  ...res,
                };
              }
            );
            this.laboratoryLength.data = catLaboratories;

            // const { insurancePercentage, status, deductible, statusCopay } = this.isInsurance(insurance);
            this.isInsuranceOnly = insurance.isInsuranceOnly;
            this.isInsuranceCondition = status;
            this.isCopayment = statusCopay;
            this.insuranceDetails = {
              coInsuragePercentage: insurancePercentage,
              deductibleValue: deductible,
              deductibleProgress: deductible,
            };

            this.interventionsProcedure = {
              tests: testUser.map((item) => {
                let id = item.office_visitable_id;
                return {
                  id,
                  ...item,
                };
              }),
              procedures: procedureUser.map((item) => {
                let id = item.office_visitable_id;
                return {
                  id,
                  ...item,
                };
              }),
            };

            this.treatmentOptions = this.checkPromotion(
              treatments,
              treatmentUser
            );
            this.promotionOfficeVisit.promotionTreatment = treatmentUser.map(
              (item) => {
                if (item.pivot.promotion) {
                  return {
                    id: item.pivot.office_visitable_id,
                    ...item,
                  };
                }
              }
            );

            this.treatmentProcedure = this.treatmentOptions.map((item) => {
              return {
                id: item.id,
                cant: null,
                name: item.name,
                informed_consent: item.informed_consent,
                price: is_only_calculate_insurance_visit && item.insurance_price ? parseInt(item.insurance_price) : parseInt(item.price),
                options: [],
              };
            });

            this.treatmentProcedure.forEach((iterator, index) => {
              treatmentUser.forEach((elem) => {
                if (iterator.id === elem.office_visitable_id) {
                  this.treatmentProcedure[index].cant = elem.pivot.cant;
                }
              });
            });

            let diagnosticUser = diagnostic_imagings;
            if (
              this.isPromotion &&
              diagnostic_imagings.some((item) => item.pivot.promotion)
            ) {
              const { promitions, array } = this.clearPromotions(
                diagnostic_imagings,
                CategoryDiagnosticImaging,
                "diagnostic_imagings"
              );
              diagnosticUser = array;
              this.promotionOfficeVisit.promotionImaging = promitions;
            }
            this.diagnosticSelected = this.createInicialCategories(
              diagnosticUser,
              "category_diagnostic_imagings"
            );

            const catImaging = CategoryDiagnosticImaging.map(
              ({ diagnostic_imagings, ...res }) => {
                const diagnosticM = diagnostic_imagings.filter(
                  (laboratory) => laboratory.price != null
                );
                return {
                  diagnosticM,
                  ...res,
                };
              }
            );
            this.diagnosticLength.data = catImaging;

            this.costProcedure = {
              signature,
              adjusment: ajustment === null ? 0 : ajustment,
              authorization,
            };

            if (this.isInsuranceCondition) {
              this.consultationPrice.price = visitRates;
            } else {
              this.consultationPrice.price = consult_prices.price;
            }

            if (!this.isInHouseCondition) {
              this.visitRates = visit_rates.map((data) => {
                return {
                  ...data,
                  value: 0,
                };
              });

              /* nivel en 0 si tiene un CO y no deducible */
              if (visitRates != null) {
                this.consultationPrice.price = {
                  ...visitRates,
                  value: 0,
                };
              }
            } else {
              this.visitRates = visit_rates;
            }
            this.medicalHistoryConditional = true;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.$router.go(-1);
          });
      } else {
        this.$swal({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

        this.$router.go(-1);
      }
    },
    async omaSubmitted() {
      this.sendingForm = true;
      const finallyAilment = this.createFinalArray(
        this.medicalHistory.ailments
      );
      const finallyAllergies = this.createFinalArray(
        this.medicalHistory.allergies
      );
      const finallyMedicationInformation = this.createFinalArray(
        this.medicalHistory.medicationInformation
      );

      let medicalHistory = {
        ailments: finallyAilment,
        allergies: finallyAllergies,
        medicationInformation: finallyMedicationInformation,
      };

      const finallyReviewSystem = this.createFinalCategories(
        this.reviewSystemSelected
      );
      const finallyLaboratory = this.createFinalCategories(
        this.laboratorySelected,
        true
      );
      const finallyDiagnosticImagings = this.createFinalCategories(
        this.diagnosticSelected,
        true
      );
      const { laboratoriesPack } = this.procedurePacks;
      const laboratories_pack = this.createFinalArray(laboratoriesPack);

      const finallyTest = this.createFinalArray(
        this.interventionsProcedure.tests
      );

      const finallyProcedures = this.createFinalArray(
        this.interventionsProcedure.procedures
      );

      const filterTreatment = this.treatmentProcedure.filter((item) => {
        if (item.cant != null) {
          return item;
        }
      });

      let { examination, describeAbnormal, describeDiagnoses, diagnoses } =
        this.assessmentProcedure;

      // diagnoses = diagnoses.map((item) => {
      //   return item.id;
      // });

      const rates_types = this.isInsuranceCondition
        ? this.consultationPrice?.price?.id
        : null;

      let checkPhoto = false;
      let dataOma = {
        // room: this.detail.room,
        // vital_signs: this.vitalSign,
        review_of_system: finallyReviewSystem,
        laboratories: finallyLaboratory,
        laboratories_pack,
        diagnostic_imagings: finallyDiagnosticImagings,
        procedures: finallyProcedures,
        tests: finallyTest,
        examination,
        // describe_abnormal: describeAbnormal,
        // describe_diagnoses: describeDiagnoses,
        diagnoses,
        treatments: filterTreatment,
        // history: {
        //   ...this.medicalHistory,
        //   ...medicalHistory,
        // },
        total: parseFloat(this.totalPriceProcedure),
        rates_types,
        ajustment: this.costProcedure.adjusment,
        authorization: this.costProcedure.authorization,
        // laboratory_promo: this.promotionOfficeVisit.laboratoryPromotion,
        procedure_promo: this.promotionOfficeVisit.promotionProcedure,
        test_promo: this.promotionOfficeVisit.promotionTest,
        // diagnostic_imaging_promo: this.promotionOfficeVisit.promotionImaging,
      };
      const savePhoto = this.$refs.signaturePad;
      if (this.costProcedure.signature) {
        checkPhoto = true;
        dataOma.signature = this.costProcedure.signature;
      } else if (savePhoto && !savePhoto.isEmpty()) {
        checkPhoto = true;
        const { data } = savePhoto.saveSignature();
        const dataPhoto = convertirBase64AFile(data, "miImagen.jpg");
        const photoUrl = await this.uploadPhoto(dataPhoto);
        dataOma.signature = photoUrl;
      } else {
        this.$refs.toast.danger("Signature required");
        this.sendingForm = false;
      }

      if (checkPhoto) {
        axiosOV
          .sendProcedureOmaInsurance(this.idOfficeVisit, {
            ...dataOma,
            dataSet: this.arrayInsurance,
          })
          .then((res) => {
            const { type, message } = notificationCheck(res);
            this.sendingForm = false;
            this.$refs.toast[type](message);
            this.$router.go(-1);
          })
          .catch((e) => {
            this.$refs.toast.danger("the procedure has not been updated");
          });
      }
    },
    validationPatients() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    isInsurance(insurance) {
      const { deductible, co_insurance_percentage, copay, isInsuranceOnly } =
        insurance;
      let data = {
        insurancePercentage: 0,
        status: false,
        deductible: 0,
        statusCopay: copay === null ? true : false,
      };

      if (
        typeof deductible === "number" ||
        typeof copay === "number" ||
        typeof co_insurance_percentage === "number" ||
        isInsuranceOnly === true
      ) {
        data = {
          ...data,
          insurancePercentage: co_insurance_percentage,
          status: true,
          deductible: deductible,
        };
      }
      return data;
    },
    async omaProcedure(id) {
      const data = {
        office_visits_id: id,
      };
      const { status, message } = await axiosOV.assignToOma(data);
      if (status === "OK") {
        return { check: true };
      } else {
        return { check: false, message };
      }
    },
    totalAdjustment(value) {
      this.costProcedure.adjusment = value;
    },
    totalPrice(value) {
      if (this.isInsuranceOnly) {
        this.totalPriceProcedure = 0;
      } else {
        if (this.isPackageOfficeVisit) {
          this.totalPriceProcedure += this.totalPricePackage;
        }
        this.totalPriceProcedure = value;
      }
    },
    validationVitalSings() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormCost() {
      return new Promise((resolve, reject) => {
        this.$refs.costRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    getValidResult(item) {
      const name = item.tab.title;
      const nameData = {
        LABORATORY: this.laboratorySelected,
        "DIAGNOSTIC IMAGING": this.diagnosticSelected,
        "IH INTERVENTIONS": this.interventionsProcedure,
      };
      const dataSection = nameData[name];
      for (const iterator in dataSection) {
        for (const itemItem of dataSection[iterator]) {
          if (itemItem.pivot && itemItem.pivot.status === false) {
            item.isPendient = true;
            break;
          }
        }
      }
      if (item.isPendient) return "text-warning";
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },
    async uploadPhoto(file) {
      const formData = new FormData();
      formData.append("type", "SIGNATURE");
      formData.append("file", file);
      const { dir_img } = await axiosEC.createImage(formData);
      return dir_img;
    },
    checkPromotion(treatmentsOptions, treatmentUser) {
      const treatmentFinal = treatmentsOptions.map((item) => {
        const filterPromotion = treatmentUser.filter((itemFilter) => {
          return (
            itemFilter.pivot.office_visitable_id === item.id &&
            itemFilter.pivot.promotion === true
          );
        });
        if (filterPromotion.length > 0) {
          return { ...item, promotion: true };
        } else {
          return { ...item, promotion: false };
        }
      });
      return treatmentFinal;
    },
    createInicialCategories(arrayBackend, nameIterator) {
      const categoriesInicialTemporal = {};
      arrayBackend.forEach((x) => {
        if (!categoriesInicialTemporal.hasOwnProperty(x[nameIterator].name)) {
          categoriesInicialTemporal[x[nameIterator].name] = [];
        }
        if (nameIterator === "category_diagnostic_imagings") {
          let id = x.office_visitable_id;
          categoriesInicialTemporal[x[nameIterator].name].push({ ...x, id });
        } else {
          categoriesInicialTemporal[x[nameIterator].name].push(
            x.assessmentable_id
          );
        }
      });
      return categoriesInicialTemporal;
    },
    createFinalCategories(arrayBackend, categories = false) {
      const finallyArrayCategories = [];
      for (const key in arrayBackend) {
        if (Object.hasOwnProperty.call(arrayBackend, key)) {
          if (categories) {
            finallyArrayCategories.push(
              ...arrayBackend[key].map((item) => item.id)
            );
          } else {
            finallyArrayCategories.push(...arrayBackend[key]);
          }
        }
      }
      return finallyArrayCategories;
    },
    createFinalArray(arrayBackend) {
      const arrayNormal = [];
      for (const iterator of arrayBackend) {
        arrayNormal.push(iterator.id || iterator.historyble_id);
      }
      return arrayNormal;
    },
    clearPromotions(arr, arrAll, arrayName) {
      // Funcion para limpiar el array de promociones ( separarlas )
      const arrayPromotion = [];
      const arrayWithoutPromotion = [];

      // obtiene los items que tiene promotion en true
      for (const item of arr) {
        item.pivot.promotion
          ? arrayPromotion.push(item)
          : arrayWithoutPromotion.push(item);
      }

      for (const category of arrAll) {
        const data = category[arrayName].filter((item) => {
          // saca los items que estan arrayPromotion del array original
          return !arrayPromotion.some(
            (itemSome) =>
              (itemSome.id || itemSome.office_visitable_id) === item.id
          );
        });
        category[arrayName] = data;
      }
      return { promitions: arrayPromotion, array: arrayWithoutPromotion };
    },
    changePackageOfficeVisit({
      clinical_package_id,
      price,
      consult,
      countUpdatePackage,
    }) {
      this.countUpdatePackage = countUpdatePackage;
      this.isPackageOfficeVisit = clinical_package_id || null;
      this.totalPricePackage = price ? parseFloat(price) : 0;
      this.isPromotion = clinical_package_id === null ? false : true;

      this.consultationPrice.price = consult;
    },
    changeTreatments(event) {
      this.treatmentOptions = this.checkPromotion(this.treatmentAll, event);

      this.promotionOfficeVisit.promotionTreatment = event.map((item) => {
        if (item.pivot.promotion) {
          return {
            id: item.pivot.office_visitable_id,
            ...item,
          };
        }
      });

      this.treatmentProcedure = this.treatmentOptions.map((item) => {
        return {
          id: item.id,
          cant: null,
          name: item.name,
          informed_consent: item.informed_consent,
          price: item.price,
          options: [],
        };
      });

      this.treatmentProcedure.forEach((iterator, index) => {
        event.forEach((elem) => {
          if (iterator.id === elem.pivot.office_visitable_id) {
            this.treatmentProcedure[index].cant = elem.pivot.cant;
          }
        });
      });
    },
    changePackageSelected(event) {
      this.isPackageOfficeVisitSelected = event || null;
    },
    showSubmit(index){
      if(index === 3 && this.is_only_calculate_self_pay_visit === false) return true;
      return (index === 5);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.bounce-leave-active {
  transition: opacity 0.5s;
}
.bounce-enter-active {
  animation: bounceIn 0.5s;
}
.bounce-enter, .bounce-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
@media screen and (max-width: 810px) {
  .vertical.wizard-vertical.vue-form-wizard .wizard-navigation .wizard-nav {
    min-width: 32%;
  }
}
.wizard-nav.wizard-nav-pills li:nth-child(6) {
  background: rgb(241, 197, 0);
  background: linear-gradient(
    140deg,
    rgba(241, 197, 0, 1) 0%,
    rgba(255, 255, 255, 0) 50%
  );
}
</style>
