<template>
  <b-card class="card-total">
    <div class="d-flex justify-content-between flex-wrap rounded-lg">
      <div class="d-flex flex-column align-items-start m-1">
        <p for="" class="mb-0">Deductible ($)</p>
        <h5 class="font-weight-bolder">
          {{ isNaN(insuranceDetails.deductibleProgress) == true ? 0 : insuranceDetails.deductibleProgress }}
        </h5>
      </div>
      <div class="d-flex flex-column align-items-start m-1">
        <p for="" class="mb-0">COI-Fee (%)</p>
        <h5 class="font-weight-bolder">
          {{ insuranceDetails.coInsuragePercentage }}
        </h5>
      </div>
      <div class="d-flex flex-column align-items-start m-1">
        <p for="" class="mb-0">COI-Charges ($)</p>
        <h5 class="font-weight-bolder">{{ totalPriceWithInsurance }}</h5>
      </div>
      <div class="d-flex flex-column align-items-start m-1">
        <p for="" class="mb-0">Section ($)</p>
        <h5 class="font-weight-bolder">{{ totalPriceSection }}</h5>
      </div>
      <div class="d-flex flex-column align-items-start m-1">
        <p for="" class="mb-0">Total ($)</p>
        <h5 class="font-weight-bolder">{{ totalPrice }}</h5>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
export default {
  components: {
    BCard,
  },
  props: {
    insuranceDetails: {
      type: Object,
      default: () => {},
    },
    totalPriceSection: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
    totalPriceWithInsurance: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style scoped lang="scss">
.card-total .card-body {
  padding: 0 !important;
}
.card-total {
  position: relative;
  bottom: 70px;
}
</style>
