<template>
  <div class="mt-2">
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Charges and Costs</h5>
        <small class="text-muted">Enter the costs of the procedure</small>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Consultation" label-for="consultation">
          <validation-provider
            #default="{ errors }"
            name="Consultation"
            rules="required"
          >
            <!-- no eliminar -->
            <b-form-input
              v-if="!isInsurance"
              id="consultation"
              v-model="consultation.price"
              disabled
              :state="errors.length > 0 ? false : null"
            />
            <v-select
              v-else
              id="consultation"
              v-model="consultation.price"
              :options="visitRates"
              @input="addItem($event)"
              label="value"
            >
              <template slot="option" slot-scope="option">
                <div class="d-flex">
                  Valor: {{ option.value }} - Nivel: {{ option.level }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="d-flex">
                  Valor: {{ option.value }} - Nivel: {{ option.level }}
                </div>
              </template>
            </v-select>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Diagnostic imagings"
          label-for="diagnostic-imagings"
        >
          <validation-provider
            #default="{ errors }"
            name="Diagnostic imagings"
            rules="required"
          >
            <b-form-input
              id="diagnostic-imagings"
              disabled
              :value="totalPriceDiagnosticImaging"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Subtotal" label-for="subtotal">
          <validation-provider
            #default="{ errors }"
            name="Subtotal"
            rules="required"
          >
            <b-form-input
              id="subtotal"
              :value="subtotalPrice"
              disabled
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Adjustment" label-for="adjustment">
          <validation-provider
            #default="{ errors }"
            name="Adjustment"
            rules="required|min_value:0"
          >
            <b-form-input
              :disabled="isDisabledAdjusment && isInsuranceFacility == false"
              id="adjustment"
              v-model.number="getCostProcedure.adjusment"
              :state="errors.length > 0 ? false : null"
              @change="$emit('totalAdjustment', getCostProcedure.adjusment)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Total" label-for="total">
          <validation-provider
            #default="{ errors }"
            name="Total"
            rules="required"
          >
            <b-form-input
              id="total"
              disabled
              :value="totalPrice"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12" xl="6">
        <slot></slot>
      </b-col>
      <b-row>
        <b-col md="6">
          <b-form-checkbox v-model="getCostProcedure.authorization">
            IM {{ detailsPatient.name }} {{ detailsPatient.lastName }} Accept
            terms and conditions explained by the medical team of NORTH TEXAS
            TELEMEDICINE facility Clinica La Virgen de Guadalupe
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-row>
    <CostOverrun v-if="conditionOverrun" />
  </div>
</template>

<script>
import { ValidationProvider, extend } from "vee-validate";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";

import CostOverrun from "../notification/CostOverrun.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,

    vSelect,

    ValidationProvider,
    CostOverrun,
  },
  props: {
    totalPriceDiagnosticImaging: {
      type: Number,
      default: 0,
    },
    totalPriceLaboratory: {
      type: Number,
      default: 0,
    },
    totalPriceInterventions: {
      type: Number,
      default: 0,
    },
    totalPriceTreatment: {
      type: Number,
      default: 0,
    },
    getCostProcedure: {
      type: Object,
      required: true,
    },
    campaignCupon: {
      type: Object,
      required: true,
    },
    detailsPatient: {
      type: Object,
      required: true,
    },
    consultation: {
      type: [Object, null],
      required: false,
    },
    descountInsurance: {
      type: Number,
      required: false,
    },
    visitRates: {
      type: Array,
      required: false,
    },
    isCopayment: {
      type: Boolean,
      requerid: true,
    },
    isPromotion: {
      type: Boolean,
      requerid: true,
    },
    isInsurance: {
      type: Boolean,
      requerid: true,
    },
    isPackageOfficeVisit: {
      type: Number,
      requerid: true,
    },
    totalPricePackage: {
      type: Number,
      requerid: true,
    },
    totalFinalWithInsurance: {
      type: Number,
      required: false,
    },
    isVisitAdjustment: {
      type: Boolean,
      required: true,
    },
    isInHouse: {
      type: Boolean,
      requerid: true,
    },
    isInsuranceFacility: {
      type: Boolean,
      requerid: false,
      default: false
    }
  },
  watch: {
    totalPrice(value) {
      this.$emit("totalPrice", value);
    },
  },
  data() {
    return {
      options: {
        penColor: "#000",
      },
      selected: false,
    };
  },
  computed: {
    subtotalPrice() {
      // if(!this.isInHouse){
      //   this.consultation.price.value = 0;
      // }
      let pricePackage = 0;
      pricePackage = this.totalPricePackage;

      let priceSelected = 0;
      if (this.isInsurance) {
        priceSelected = this.consultation.price
          ? this.consultation.price.value
          : 0;
      } else {
        priceSelected = this.consultation.price;
      }
      return (
        this.totalPriceDiagnosticImaging +
        this.totalPriceLaboratory +
        this.totalPriceInterventions +
        this.totalPriceTreatment +
        priceSelected + 
        pricePackage
      );
    },
    totalPrice() {
      if (this.isInsurance) {
        return (
          this.totalFinalWithInsurance -
          parseFloat(this.getCostProcedure.adjusment)
        );
      }
      return this.subtotalPrice - parseFloat(this.getCostProcedure.adjusment);
    },
    conditionOverrun() {
      if (this.totalPrice > 500) return true;
      else false;
    },
    isDisabledAdjusment() {
      if(this.campaignCupon?.id) {
        this.getCostProcedure.adjusment = this.AdjustmentWithCupon(this.campaignCupon.campaign.type, this.campaignCupon.campaign.value)
        return true
      }
      if(this.isInsurance){
        return false;
      }
      if (this.isVisitAdjustment && this.isInsurance) {
        return false;
      } else {
        return this.isPromotion || this.isInsurance || this.subtotalPrice === 0;
      }
    },
  },
  methods: {
    AdjustmentWithCupon(type, value) {
      let total = 0
      if(type == "value") {
        total = value
      } else {
        total = Math.floor(value/ 100 * this.subtotalPrice)
      }
      return total
    },
    addItem(item) {
      if (item) {
        const data = {
          id: item.id,
          type: 1,
          itemId: 10,
          costProcedure: true,
          total: item.value,
        };
        this.$emit("calculateInsurance", data);
      } else {
        const data = { type: 1, itemId: 10, costProcedure: true };
        this.$emit("calculateInsurance", data, "delete");
      }
    },
  },
};
</script>

<style></style>
