<template>
  <b-row class="mt-2">
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">Vital Signs</h5>
      <small class="text-muted">Enter the corresponding vital signs</small>
    </b-col>
    <b-col md="6" lg="3" v-for="(item, id) in signVitalsOptions" :key="item.id">
      <b-form-group :label="item.name" :label-for="item.prefix">
        <validation-provider
          #default="{ errors }"
          :name="item.name"
          :rules="item.id == 1 ? 'required' : 'required|decimal'"
        >
          <b-input-group :append="item.unit" class="input-group-merge">
            <b-form-input
              :id="item.prefix"
              v-model="vitalSign[id].value"
              :state="errors.length > 0 ? false : null"
              class="form-control"
              :placeholder="item.unit"
              :readonly="item.id == 8"
              
            />
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { BRow, BCol, BFormGroup, BInputGroup, BFormInput } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
  },
  props: {
    vitalSign: {
      type: Array,
      required: true,
    },
    signVitalsOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
    };
  },
  watch: {
    vitalSign: {
      handler() {
        this.calculateIBM()
      },
      deep: true
    },
  },
  methods: {
    calculateIBM() {
      const wieghtData = parseInt(this.vitalSign[5].value);
      const heightData = parseInt(this.vitalSign[6].value);
      const result = (wieghtData / Math.pow(heightData, 2)) * 703
      this.vitalSign[7].value = Math.round(result * 100) / 100;
    },
  },
};
</script>

<style>
.form-control[readonly]  {
  background-color: inherit !important;
}
</style>