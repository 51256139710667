<template>
  <b-sidebar
    id="add-new-medication-sidebar"
    :visible="isEditMedicationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-edit-medication-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Edit Medication</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group :label="$t('Form.Name')" label-for="name">
              <v-select
                id="category"
                v-model="stateData.medication"
                :state="getValidationState(validationContext)"
                :options="optionsMedications"
                :clearable="false"
                :reduce="(value) => value.id"
                label="name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- startDate -->
          <validation-provider
            #default="validationContext"
            name="start_date"
          >
            <b-form-group label="Start Date" label-for="start_date">
              <flat-pickr
                id="date_birth"
                v-model="stateData.startDate"
                :config="config"
                class="form-control"
                placeholder="MM-DD-YYYY"
                type="date"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="stop_date"
          >
            <b-form-group label="Stop Date" label-for="stop_date">
              <flat-pickr
                v-model="stateData.stopDate"
                :config="config"
                class="form-control"
                placeholder="MM-DD-YYYY"
                type="date"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="{ errors }" name="Note" rules="">
            <b-form-group label="Note" label-for="note">
              <b-form-textarea
                id="note"
                v-model="stateData.note"
                placeholder="Note"
                no-resize
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- status -->
          <validation-provider
            #default="validationContext"
            name="Status"
            rules="required"
          >
            <b-form-group :label="$t('Form.Status')" label-for="status">
              <v-select
                id="status"
                v-model="stateData.status"
                :state="getValidationState(validationContext)"
                :options="optionsStatus"
                :reduce="(val) => val.value"
                :clearable="false"
                label="text"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="loading"
            >
              <span v-if="!loading">Edit</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              @click="hide"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ref } from "@vue/composition-api";

import axiosM from "@/services/office-visit/medications";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    SpinnerLoading,

    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditMedicationSidebarActive",
    event: "update:is-edit-medication-sidebar-active",
  },
  props: {
    isEditMedicationSidebarActive: {
      type: Boolean,
      required: true,
    },
    optionsMedications: {
      type: Array,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const config = {
      altInput: true,
      altFormat: "m-d-Y",
      dateFormat: "m-d-Y",
      allowInput: true,
    };

    const optionsStatus = [
      { text: "Taking", value: 1 },
      { text: "Not Taking", value: 2 },
      { text: "Discontinued", value: 3 },
      { text: "Unknown Status", value: 4 },
    ];

    const {
      medications_id: medication,
      start_date: startDate,
      start_stop: stopDate,
      note,
      status_medications_id: status,
      historyble_id,
    } = props.itemEdit.value;

    const stateData = ref(
      JSON.parse(
        JSON.stringify({
          medication,
          startDate,
          stopDate,
          note,
          status,
          historyble_id,
        })
      )
    );
    const resetuserData = () => {
      stateData.value = JSON.parse(
        JSON.stringify({
          medication,
          startDate,
          stopDate,
          note,
          status,
          historyble_id,
        })
      );
    };

    const loading = ref(false);
    const onSubmit = () => {
      loading.value = true;
      const MedicationItem = {
        medications_id: stateData.value.medication,
        start_date: stateData.value.startDate,
        start_stop: stateData.value.stopDate,
        note: stateData.value.note,
        status_medications_id: stateData.value.status,
      };
      axiosM
        .paientMedicationUpdate(stateData.value.historyble_id, MedicationItem)
        .then(() => {
          loading.value = false;
          resetuserData();
          emit("editMedicationSuccess", true);
        })
        .catch(() => {
          emit("editMedicationSuccess", false);
          loading.value = false;
        });
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);
    return {
      stateData,
      loading,
      config,
      optionsStatus,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>