import { userAxios } from '../index'

const officeVisitList = async (perPage) => {
    try {
        return await userAxios.get(`office_visit/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitListPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`office_visit/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`office_visit/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`office_visit/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const officeVisitDetailt = async (id) => {
    try {
        return await userAxios.get(`office_visit/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const roomsAvailable = async () => {
    try {
        return await userAxios.get(`rooms`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const roomsCheckAvailable = async (id, idRoom) => {
    try {
        return await userAxios.get(`rooms/${id}/${idRoom}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureNurse = async (id, data) => {
    try {
        return await userAxios.post(`office_visit/nurse_procedure/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureOma = async (id, data) => {
    try {
        return await userAxios.post(`office_visit/oma_procedure/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}
const assignToOma = async (id) => {
    try {
        return await userAxios.post(`office_visit/visiting_assignment`, id).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureCheck = async(id, data) => {
    try {
        return await userAxios.put(`office_visit/procedure-confirm/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedurePay = async(id, data) => {
    try {
        return await userAxios.post(`office_visit/pay/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedurePayPartial = async(id, data) => {
    try {
        return await userAxios.post(`appointment/pending-pay/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const dataForNurse = async(id) => {
    try {
        return await userAxios.get(`peticionMasterNurse/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const dataForOMA = async(id) => {
    try {
        return await userAxios.get(`peticionMasterOma/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureOmaInsurance = async (id, data) => {
    try {
        return await userAxios.get(`insurance-appointment/show/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const sendProcedureOmaInsurance = async (id, data) => {
    try {
        return await userAxios.post(`insurance-appointment/confirm-procedures/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const pendingPayMembership = async (id) => {
    try {
        return await userAxios.get(`membership/membership-pending-pay/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const removeMembership = async (id, data) => {
    try {
        return await userAxios.post(`membership/remove-benefits-appointment/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    officeVisitList,
    officeVisitListPagination,
    officeVisitFilter,
    officeVisitFilterPagination,
    officeVisitDetailt,
    roomsAvailable,
    roomsCheckAvailable,
    procedureNurse,
    procedureOma,
    assignToOma,
    procedureCheck,
    procedurePay,
    procedurePayPartial,
    dataForNurse,
    dataForOMA,
    procedureOmaInsurance,
    sendProcedureOmaInsurance,
    pendingPayMembership,
    removeMembership,
}