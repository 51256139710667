<template>
  <b-modal
    id="cost"
    v-model="conditionRefund"
    hide-footer
    hide-header
    centered
    content-class="transparent"
    size="md"
    ref="CostModal"
    @hide="onHide"
  >
    <b-card class="position-static" body-border-variant="primary">
      <label for="">Name</label>
      <b-form-input
        disabled
        :value="`${dataTreatment.user.name} ${dataTreatment.user.lastName}`"
      >
      </b-form-input>

      <label for="" class="mt-2">Treatment</label>
      <b-form-input disabled :value="`${dataTreatment.item.name}`">
      </b-form-input>

      <div class="d-flex">
        <VueSignaturePad
          id="signature"
          width="100%"
          height="20vh"
          ref="signaturePad"
          class="border border-primary rounded mt-2"
          :options="{
            onBegin: () => {
              $refs.signaturePad.resizeCanvas();
            },
          }"
        />
        <div class="align-self-center">
          <b-button
            class="btn-icon align-self-center mx-1 cursor-pointer"
            variant="danger"
            @click="clearSignature"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </b-button>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-2">
        <b-button
          type="button"
          class="mr-2"
          variant="outline-danger"
          @click="onHide"
        >
          {{ $t("Cancel") }}
        </b-button>
        <b-button :disabled="loading" variant="primary" @click="onSubmit">
          <span v-if="!loading">{{ $t("Add") }}</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </div>
    </b-card>
    <ToastNotification ref="toast" />
  </b-modal>
</template>

<script>
import { BModal, BCard, BButton, BFormInput } from "bootstrap-vue";
import SpinnerLoading from "@/components/SpinnerLoading";
import ToastNotification from "@/components/ToastNotification";
import { convertirBase64AFile } from "@/mixins/Base64ToFile";

import axiosP from "@/services/patient/appointment";
import axiosEC from "@/services/external-settings/images";

export default {
  components: {
    BModal,
    BCard,
    BButton,
    BFormInput,
    SpinnerLoading,

    ToastNotification,
  },
  props: {
    dataTreatment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      conditionRefund: true,
      fields: [{ key: "id", sortable: true }, { key: "note" }],
      loading: false,
      signatureConsent: null,
    };
  },
  methods: {
    onHide() {
      this.$emit("closeModalConset", false);
    },
    saveSignature() {
      const { isEmpty } = this.$refs.signaturePad.saveSignature();
      return !isEmpty;
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    },
    async onSubmit() {
      this.loading = true;
      const savePhoto = this.$refs.signaturePad;
      if (savePhoto && !savePhoto.isEmpty()) {
        const { data } = savePhoto.saveSignature();
        const dataPhoto = convertirBase64AFile(data, 'miImagen.jpg');
        const photoUrl = await this.uploadPhoto(dataPhoto);
        const dataForm = {
          signature: photoUrl,
        };
        this.$emit("saveConsent", dataForm);
        this.$emit("closeModalConset", false);
        this.loading = false;
      }
    },
    async uploadPhoto(file) {
      const formData = new FormData();
      formData.append("type", "CHECK_CONSENT");
      formData.append("file", file);
      const { dir_img } = await axiosEC.createImage(formData);
      return dir_img;
    },
  },
};
</script>

<style lang="scss">
.transparent {
  box-shadow: none !important;
  background-color: transparent !important;
}
</style>
