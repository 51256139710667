import { userAxios } from '@/services'

const procedureList = async (perPage) => {
    try {
        return await userAxios.get(`procedures/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureCreate = async (data) => {
    try {
        return await userAxios.post('procedures', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureUpdate = async (id, data) => {
    try {
        return await userAxios.put(`procedures/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
} 

const procedureDelete = async (id) => {
    try {
        return await userAxios.delete(`procedures/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedurePagination = async (perPage, page) => {
    try {
        return await userAxios.get(`procedures/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`procedures/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const procedureFilterPagination = async (perPage, page, data ) => {
    try {
        return await userAxios.post(`procedures/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    procedureList,
    procedureCreate,
    procedureUpdate,
    procedureDelete,
    procedurePagination,
    procedureFilter,
    procedureFilterPagination
}