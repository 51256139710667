import { userAxios } from '../index'

const packageCategorieList = async () => {
    try {
        return await userAxios.get(`clinical-package-categories`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const packageByCategories = async (clinicalPackageCategoryId) => {
    try {
        return await userAxios.get(`procedure-packages/category/${clinicalPackageCategoryId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}


export default {
    packageCategorieList,
    packageByCategories
}