<template>
    <component :is="officeVisitType[typeClinic]"></component>
</template>

<script>
import OmaProcedure from './OmaProcedure.vue';
import OmaProcedureInsurance from './insurance/OmaProcedureInsurance.vue';

export default {
    components: {
        OmaProcedure,
        OmaProcedureInsurance,
    },
    created(){
        this.getFacility();
        const facilityInUse = this.$variableGlobal.isMedicalCenter;
        this.typeClinic = facilityInUse ? 2 : 1;
    },
    data(){
        return {
            typeClinic: 1,
            officeVisitType: {
                1: 'OmaProcedure',
                2: 'OmaProcedureInsurance'
            }
        }
    },
    watch: {
        "$variableGlobal.isMedicalCenter": function (value) {
            this.typeClinic = value ? 2 : 1;
        },
    },
    methods: {
        getFacility(){
            const facility = JSON.parse(atob(localStorage.getItem('Facility')));
            this.typeClinic = facility.insurance ? 2 : 1;
        }
    }
}
</script>