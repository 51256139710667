var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mt-2"},[(_vm.isAddNewMedicationUserSidebarActive)?_c('MedicationAddUser',{attrs:{"is-add-new-medication-user-sidebar-active":_vm.isAddNewMedicationUserSidebarActive,"medicalHistory":_vm.medicalHistory,"optionsMedications":_vm.medicationsOptions},on:{"update:isAddNewMedicationUserSidebarActive":function($event){_vm.isAddNewMedicationUserSidebarActive=$event},"update:is-add-new-medication-user-sidebar-active":function($event){_vm.isAddNewMedicationUserSidebarActive=$event},"createMedication":_vm.createMedication,"createMedicationUser":_vm.createMedicationUser}}):_vm._e(),(_vm.isEditMedicationSidebarActive)?_c('MedicationEdit',{attrs:{"is-edit-medication-sidebar-active":_vm.isEditMedicationSidebarActive,"optionsMedications":_vm.medicationsOptions,"itemEdit":_vm.itemEdit},on:{"update:isEditMedicationSidebarActive":function($event){_vm.isEditMedicationSidebarActive=$event},"update:is-edit-medication-sidebar-active":function($event){_vm.isEditMedicationSidebarActive=$event},"editMedicationSuccess":_vm.editMedicationSuccess}}):_vm._e(),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Medical History")]),_c('small',{staticClass:"text-muted"},[_vm._v("Enter the patient's medical history.")])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"PMHx"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"pmhx","label":"PMHx","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"pmhx","options":_vm.ailmentOptions.data,"multiple":"","label":"name"},on:{"search":_vm.searchAilment},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?_c('NewItemSelect',{attrs:{"search":search},on:{"onSaveVisitReason":function($event){return _vm.onSaveAilment(search)}}}):_vm._e()]}}],null,true),model:{value:(_vm.medicalHistory.ailments),callback:function ($$v) {_vm.$set(_vm.medicalHistory, "ailments", $$v)},expression:"medicalHistory.ailments"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Allergies"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"allergies","label":"Allergies","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"allergies","options":_vm.allergiesOptions.data,"multiple":"","label":"name"},on:{"search":_vm.searchAllergies},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?_c('NewItemSelect',{attrs:{"search":search},on:{"onSaveVisitReason":function($event){return _vm.onSaveAllergy(search)}}}):_vm._e()]}}],null,true),model:{value:(_vm.medicalHistory.allergies),callback:function ($$v) {_vm.$set(_vm.medicalHistory, "allergies", $$v)},expression:"medicalHistory.allergies"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-2"},[_c('b-button',{attrs:{"disabled":_vm.loading,"variant":"primary"},on:{"click":function($event){_vm.isAddNewMedicationUserSidebarActive = true}}},[(!_vm.loading)?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t("Add"))+" Medication User")]):_c('span',[_c('SpinnerLoading')],1)])],1),_c('b-table',{staticClass:"position-relative text-center",attrs:{"items":_vm.medicalHistoryMutable,"fields":_vm.fields,"striped":"","bordered":"","responsive":"","hover":"","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(note)",fn:function(data){return [_c('div',{staticClass:"overflow-auto",staticStyle:{"height":"100px"}},[_vm._v(" "+_vm._s(data.item.note)+" ")])]}},{key:"cell(status_medications_id)",fn:function(data){return [_c('b-button-group',{attrs:{"size":"sm","role":"group","id":"toolBtns"}},_vm._l((_vm.stateMedications),function(state){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(state.name),expression:"state.name",modifiers:{"hover":true,"top":true}}],key:state.id,staticClass:"btn btn-icon",class:{
                active: data.item.status_medications_id === state.id,
              },style:({
                background:
                  data.item.status_medications_id === state.id
                    ? state.color
                    : '',
                color:
                  data.item.status_medications_id === state.id ? 'white' : '',
              }),attrs:{"type":"button","variant":"outline-secondary"}},[_vm._v(" "+_vm._s(state.prefix)+" ")])}),1)]}},{key:"cell(actions)",fn:function(data){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Update'),expression:"'Update'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-primary"},on:{"click":function($event){return _vm.editMedication(data.item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(40, 199, 111, 0.15)'),expression:"'rgba(40, 199, 111, 0.15)'",modifiers:{"400":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Delete'),expression:"'Delete'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon",attrs:{"variant":"flat-danger"},on:{"click":function($event){return _vm.deleteMedication(data.item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }