import { userAxios } from '../index'

const appointmentList = async (id) => {
    try {
        return await userAxios.get(`patients/appointments/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const patientTreatmentConsent = async (id, data) => {
    try {
        return await userAxios.post(`office_vist/informed_consent/signature/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}



export default {
    appointmentList,
    patientTreatmentConsent
}