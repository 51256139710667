export const convertirBase64AFile = (base64, nombreArchivo) => {
    const partes = base64.split(";base64,");
    const tipo = partes[0].split(":")[1];
    const datos = window.atob(partes[1]);
    const array = new Uint8Array(datos.length);
    for (let i = 0; i < datos.length; i++) {
        array[i] = datos.charCodeAt(i);
    }
    const blob = new Blob([array], { type: tipo });
    return new File([blob], nombreArchivo, { type: tipo });
}