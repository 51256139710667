var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TestAdd',{attrs:{"is-add-new-test-sidebar-active":_vm.isAddNewTestSidebarActive},on:{"update:isAddNewTestSidebarActive":function($event){_vm.isAddNewTestSidebarActive=$event},"update:is-add-new-test-sidebar-active":function($event){_vm.isAddNewTestSidebarActive=$event},"createTest":_vm.createTest}}),_c('ProceduresAdd',{attrs:{"is-add-new-procedures-sidebar-active":_vm.isAddNewProceduresSidebarActive},on:{"update:isAddNewProceduresSidebarActive":function($event){_vm.isAddNewProceduresSidebarActive=$event},"update:is-add-new-procedures-sidebar-active":function($event){_vm.isAddNewProceduresSidebarActive=$event},"createProcedures":_vm.createProcedures}}),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mb-2",attrs:{"md":"12","lg":"5"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("IH interventions")]),_c('small',{staticClass:"text-muted"},[_vm._v("Enter the interventions.")])]),_c('b-col',{attrs:{"md":"12","lg":"7"}},[_c('CardStats',{attrs:{"totalPriceSection":_vm.totalPriceInterventions,"totalPrice":_vm.totalPrice,"totalPriceWithInsurance":_vm.totalFinalWithInsurance,"insuranceDetails":_vm.insuranceDetails}})],1),_c('b-col',{attrs:{"md":"4","lg":"5"}},[_c('validation-provider',{attrs:{"name":"Test"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"test","state":errors.length > 0 ? false : null}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":""}},[_vm._v("Test")]),_vm._l((_vm.validResultsList(
                _vm.interventionsProcedure.tests
              )),function(item,i){return _c('div',{key:i,ref:"",refInFor:true},[(item.status && item.names)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                  ("El procedimiento " + (item.names) + " no fue realizado, por favor actualizar orden")
                ),expression:"\n                  `El procedimiento ${item.names} no fue realizado, por favor actualizar orden`\n                ",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle p-0",attrs:{"variant":"flat-warning"}},[_c('feather-icon',{attrs:{"icon":"DeleteIcon"}})],1):_vm._e()],1)})],2),_c('v-select',{attrs:{"id":"mySelect","options":_vm.testOptions.data,"multiple":"","label":"name"},on:{"search":_vm.searchTests,"option:selected":function($event){return _vm.addTest($event)},"option:deselected":function($event){return _vm.addTest($event)}},model:{value:(_vm.interventionsProcedure.tests),callback:function ($$v) {_vm.$set(_vm.interventionsProcedure, "tests", $$v)},expression:"interventionsProcedure.tests"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1),(_vm.promotionOfficeVisit.promotionTest.length > 0)?_c('b-form-group',{attrs:{"label":"Promotion"}},[_c('v-select',{attrs:{"components":{ Deselect: _vm.Deselect },"id":"mySelect","multiple":"","noDrop":true,"searchable":false,"label":"name"},model:{value:(_vm.promotionOfficeVisit.promotionTest),callback:function ($$v) {_vm.$set(_vm.promotionOfficeVisit, "promotionTest", $$v)},expression:"promotionOfficeVisit.promotionTest"}})],1):_vm._e()]}}])})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"2","lg":"1"}},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("Add")))]),_c('b-button',{staticClass:"btn-icon mb-2",attrs:{"variant":"success"},on:{"click":function($event){_vm.isAddNewTestSidebarActive = true}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1),_c('b-col',{attrs:{"md":"4","lg":"5"}},[_c('validation-provider',{attrs:{"name":"Procedure"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"procedure","state":errors.length > 0 ? false : null}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":""}},[_vm._v("Procedure")]),_vm._l((_vm.validResultsList(
                _vm.interventionsProcedure.procedures
              )),function(item,i){return _c('div',{key:i,ref:"",refInFor:true},[(item.status && item.names)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
                  ("El procedimiento " + (item.names) + " no fue realizado, por favor actualizar orden")
                ),expression:"\n                  `El procedimiento ${item.names} no fue realizado, por favor actualizar orden`\n                ",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle p-0",attrs:{"variant":"flat-warning"}},[_c('feather-icon',{attrs:{"icon":"DeleteIcon"}})],1):_vm._e()],1)})],2),_c('v-select',{attrs:{"id":"mySelect","options":_vm.procedureOptions.data,"multiple":"","label":"name"},on:{"search":_vm.searchProcedures,"option:selected":function($event){return _vm.addProcedure($event)},"option:deselected":function($event){return _vm.addProcedure($event)}},model:{value:(_vm.interventionsProcedure.procedures),callback:function ($$v) {_vm.$set(_vm.interventionsProcedure, "procedures", $$v)},expression:"interventionsProcedure.procedures"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1),(_vm.promotionOfficeVisit.promotionProcedure.length > 0)?_c('b-form-group',{attrs:{"label":"Promotion"}},[_c('v-select',{attrs:{"components":{ Deselect: _vm.Deselect },"id":"mySelect","multiple":"","noDrop":true,"searchable":false,"label":"name"},model:{value:(_vm.promotionOfficeVisit.promotionProcedure),callback:function ($$v) {_vm.$set(_vm.promotionOfficeVisit, "promotionProcedure", $$v)},expression:"promotionOfficeVisit.promotionProcedure"}})],1):_vm._e()]}}])})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"2","lg":"1"}},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("Add"))+" P/D")]),_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"success"},on:{"click":function($event){_vm.isAddNewProceduresSidebarActive = true}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }